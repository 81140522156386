import React, { useState, useEffect } from 'react';
import { Table, Accordion, useAccordionButton } from 'react-bootstrap';
import { Alert, Area, Bar, Column, Gauge, Health, Insecure, Line, MultiRadial, Optimization, Pie, Radial, Risk, Unsafe, Warning, Safe, Secure, Security } from '../common/index';
import { ChartsLayout } from '../index';
import Select, {components} from 'react-select';
import { Icon } from '../common/index'
import { colors } from '../../styles/colors'
import { useTheme } from '../../styles/Theme'

function prepareData(rawData) {
  const allKeys = new Set();
  const serverKeyPattern = /^(server|instance|servername|instancename|server_name|server name|instance_name)$/i;
  let hasServerKey = false;
  let serverKeyName = '';
  const serverOptions = new Set();

  rawData.forEach(row => {
    Object.keys(row).forEach(key => {
      allKeys.add(key);
      if (!hasServerKey && serverKeyPattern.test(key)) {
        hasServerKey = true;
        serverKeyName = key;
      }
    });
    if (hasServerKey && row[serverKeyName]) {
      serverOptions.add(row[serverKeyName]);
    }
  });

  let keys = Array.from(allKeys);
  
  // Move server key to the front of the array
  if (hasServerKey) {
    keys = [serverKeyName, ...keys.filter(key => key !== serverKeyName)];
  }

  const processedData = rawData.map(row => 
    keys.map(key => row[key] !== undefined ? row[key] : null)
  );

  return { keys, processedData, hasServerKey, serverKeyName, serverOptions: Array.from(serverOptions) };
}

function TableHeader({ keys, serverKeyName, serverOptions, onServerSelect }) {
  const [menuPortalTarget, setMenuPortalTarget] = useState(null);

  const { theme } = useTheme(); // Use the custom hook to get the current theme
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    setMenuPortalTarget(document.body);
  }, []);

  const selectOptions = serverOptions.map(option => ({ value: option, label: option }));

  const CustomDropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <Icon name="Filter" /> 
    </components.DropdownIndicator>
  );

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? colors.darkBackground : colors.lightBackground,
      borderColor: isDarkMode ? colors.darkBorder : colors.lightBorder,
      minWidth: '150px',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? colors.darkBackground : colors.lightBackground,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? isDarkMode ? colors.darkHighlight : colors.lightHighlight
        : isDarkMode ? colors.darkBackground : colors.lightBackground,
      color: state.isFocused 
        ? isDarkMode ? colors.darkText : colors.lightText
        : isDarkMode ? colors.darkText : colors.lightText
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? colors.darkText : colors.lightText,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? colors.darkPlaceholder : colors.lightPlaceholder,
    }),
  };

  return (
    <thead>
      <tr>
        {keys.map((key, index) => (
          <th key={`header-${index}`}>
            {key === serverKeyName ? (
              <Select
                options={selectOptions}
                onChange={(selectedOption) => onServerSelect(selectedOption ? selectedOption.value : null)}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                styles={selectStyles}
                placeholder="Server"
                isClearable={true}
                menuPortalTarget={menuPortalTarget}
                menuPosition="fixed"
              />
            ) : (
              key
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function ExpandableCell({ content, charLimit = 80 }) {
  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    const [isCollapsed, setIsCollapsed] = useState(true);
    
    return (
      <div 
        onClick={() => {
          decoratedOnClick();
          setIsCollapsed(!isCollapsed);
        }} 
        style={{ cursor: 'pointer', width: '100%' }}
      >
        {isCollapsed ? (
          <>{content.slice(0, charLimit)}...</>
        ) : (
          <span style={{ fontWeight: 'bold' }}>Hide</span>
        )}
      </div>
    );
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="0" style={{border: "0px"}}>
        <Accordion.Header style={{margin: "0px", border: "0px"}}>
          <CustomToggle eventKey="0" />
        </Accordion.Header>
        <Accordion.Body style={{padding: "10px", margin: "0px"}}>
          {content}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function TableRow({ rowData, rowIndex }) {
  function truncateCell(cell) {
    if(!cell) return ""
    if (typeof cell === 'string' && cell.length > 150) {
      return (
        <ExpandableCell content={cell} />
      )
    } 
    return cell
  }

  return (
    <tr>
      {rowData.map((cell, cellIndex) => (
        <td 
          key={`cell-${rowIndex}-${cellIndex}`}
          style={cell && cell.length > 80 ? { padding: "0px" } : {}}
        >
          {truncateCell(cell)}
        </td>
      ))}
    </tr>
  );
}

function GenerateTable({ rawData, displayName }) {
  const [tableData, setTableData] = useState({
    keys: [],
    processedData: [],
    hasServerKey: false,
    serverKeyName: '',
    serverOptions: [],
    filteredData: []
  });

  useEffect(() => {
    const { keys, processedData, hasServerKey, serverKeyName, serverOptions } = prepareData(rawData);
    setTableData({
      keys,
      processedData,
      hasServerKey,
      serverKeyName,
      serverOptions,
      filteredData: processedData
    });
  }, [rawData]);

  const handleServerSelect = (selectedValue) => {
    if (selectedValue === null) {
      setTableData(prev => ({ ...prev, filteredData: prev.processedData }));
    } else {
      const filteredData = tableData.processedData.filter(row => row[0] === selectedValue);
      setTableData(prev => ({ ...prev, filteredData }));
    }
  };

  return (
    <div>
      <Accordion defaultActiveKey={tableData.filteredData.length > 8 ? null : "0"}>
        <Accordion.Item eventKey="0" style={{border: "0px"}}>
          <Accordion.Header>
            <h3>{displayName}</h3>
          </Accordion.Header>
          <Accordion.Body style={{padding: "0px", margin: "0px"}}>
            <Table responsive striped bordered hover style={{margin: "0px"}}>
              <TableHeader
                keys={tableData.keys}
                serverKeyName={tableData.serverKeyName}
                serverOptions={tableData.serverOptions}
                onServerSelect={handleServerSelect}
              />
              <tbody>
                {tableData.filteredData.map((row, index) => (
                  <TableRow key={`row-${index}`} rowData={row} rowIndex={index} />
                ))}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

function renderChart(type, data) {
  switch (type) {
    case 'Alert': return <Alert text={data} />;
    case 'Safe': return <Safe text={data} />;
    case 'Unsafe': return <Unsafe text={data} />;
    case 'Warning': return <Warning text={data} />;
    case 'Secure': return <Secure text={data} />;
    case 'Insecure': return <Insecure text={data} />;
    case 'Risk': return <Risk level={data.level} text={data.text} />;
    case 'Gauge': return <Gauge percent={data.percent} text={data.text} />;
    case 'Radial': return <Radial percent={data.percent} label={data.label} />;
    case 'MultiRadial': return <MultiRadial series={data.series} labels={data.labels} text={data.text} />;
    case 'Line': return <Line series={data.series} categories={data.categories} text={data.text} />;
    case 'Bar': return <Bar series={data.series} categories={data.categories} text={data.text} />;
    case 'Area': return <Area series={data.series} categories={data.categories} text={data.text} />;
    case 'Column': return <Column series={data.series} categories={data.categories} text={data.text} />;
    case 'Pie': return <Pie series={data.series} labels={data.categories} text={data.text} />;
    case 'Optimization': return <Optimization level={data.level} text={data.text} fileName={data.fileName} />;
    case 'Security': return <Security level={data.level} text={data.text} fileName={data.fileName} />;
    case 'Health': return <Health level={data.level} text={data.text} />;
    default: return null;
  }
}

const ChartsToRender = ({ data, fileName }) => {
  if (!data || (!data.charts && !data.table)) return null;

  const Charts = data.charts ?? {};
  const toRender = data.table ?? [];

  const chartTypes = ['Alert', 'Area', 'Bar', 'Column', 'Gauge', 'Health', 'Insecure', 'Line', 'Optimization', 'Pie', 'Radial', 'MultiRadial', 'Risk', 'Unsafe', 'Warning', 'Safe', 'Secure', 'Security'];
  const chartData = {};

  chartTypes.forEach(type => {
    if (Charts[type]) {
      chartData[type] = Charts[type];
    }
  });

  const chartCategories = {
    small: ['Safe', 'Unsafe', 'Alert', 'Warning', 'Secure', 'Insecure', 'Risk', 'Gauge', 'Optimization', 'Security', 'Health'],
    large: ['Line', 'Bar', 'Area', 'Column', 'Pie', 'Radial', 'MultiRadial']
  };

  const renderedCharts = {
    small: [],
    large: []
  };

  Object.entries(chartData).forEach(([type, data]) => {
    if (chartCategories.small.includes(type)) {
      renderedCharts.small.push(renderChart(type, data));
    } else if (chartCategories.large.includes(type)) {
      renderedCharts.large.push(renderChart(type, data));
    }
  });

  const displayName = fileName.replace(/_/g, ' ').replace('.JSON', '');
  const detailTable = toRender.length !== 0 ? <GenerateTable rawData={toRender} displayName={displayName} /> : <h3>&nbsp;&nbsp; {displayName}</h3>;

  return (
    <>
      {detailTable}
      <ChartsLayout smallCharts={renderedCharts.small} largeCharts={renderedCharts.large} />
    </>
  );
};

export default ChartsToRender