import React, { useState, useEffect } from 'react';
import { Icon } from './common/index'; // Make sure to install this package
import { useTheme } from '../styles/Theme';
import { colors } from '../styles/colors';
const colorOptions = [colors.success, colors.warning, colors.danger]

const ActivityItem = ({ activity, categoryDefinition }) => {
  const [isHovered, setIsHovered] = useState(false);
  const {theme} = useTheme();
  const isDarkMode = theme === 'dark';

  let severity = 2;
  if (activity.Impact_Level < 0.67) severity = 1;
  if (activity.Impact_Level < 0.34) severity = 0;

  const color = colorOptions[severity]
  const aItemStyle = {
    borderLeft: `4px solid ${color}`
  }

  if (isDarkMode) {
    aItemStyle.boxShadow = isHovered
      ? `0 0 30px ${color}90`
      : `0 0 20px ${color}40`;
  }
  
  return (
    <div className="activity-item card" style={aItemStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div className="d-flex flex-column justify-content-between p-2">
        <div className="d-flex align-items-center mb-1">
          <Icon name={categoryDefinition?.icon} className="me-1" style={{ color: color }} />
          <span className="small fw-bold">{activity.Category}</span>
        </div>
        <div className="fw-bold mb-1">{activity.Name}</div>
        <div className="small text-muted overflow-auto">{activity.Description}</div>
      </div>
    </div>
  );
};

const ActivityThread = ({ animate = false, activities: initialActivities, categoryDefinitions }) => {
  const [activities, setActivities] = useState([]);
  const [animatingIndex, setAnimatingIndex] = useState(null);

  const itemWidth = 180;
  const arrowWidth = 40;
  const gap = 16;
  
  useEffect(() => {
    if (animate) {
      let isMounted = true;

      const animateActivities = async () => {
        for (let i = 0; i < initialActivities.length; i++) {
          if (!isMounted) break;
          await new Promise(resolve => setTimeout(resolve, 300));
          setActivities(prev => [...prev, initialActivities[i]]);
          setAnimatingIndex(i);
        }
        if (isMounted) setAnimatingIndex(null);
      };

      animateActivities();

      return () => {
        isMounted = false;
      };
    } else {
      // Render all activities at once if not animating
      setActivities(initialActivities);
    }
  }, [animate, initialActivities]);

  const getItemStyle = (index) => {
    const baseStyle = {
      position: "absolute",
      transition: "right 0.2s ease-out",
    };

    const totalItems = activities.length;
    const basePosition = "0px";
    const offset = (totalItems - index - 1) * (itemWidth + arrowWidth + gap);

    return {
      ...baseStyle,
      right: `calc(${basePosition} + ${offset}px)`,
    };
  };

  return (
    <div className="w-100 p-1 position-relative" style={{ height: "150px" }}>
      {activities.map((activity, index) => (
        <React.Fragment key={activity.activityId}>
          <div className={`${animate && index === animatingIndex ? 'animate__animated animate__slideInRight' : ''}`}>
            <div style={getItemStyle(index)}>
              <ActivityItem 
                activity={activity} 
                categoryDefinition={categoryDefinitions[activity.Category]}
              />
            </div>
            {index < activities.length - 1 && (
              <div 
                style={{
                  ...getItemStyle(index),
                  right: `calc(${getItemStyle(index).right} - ${arrowWidth + gap/2}px)`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: `${arrowWidth}px`,
                  height: '100%',
                  fontSize: "30px",
                  color: '#666',
                }}
              >
                <Icon name="CaretRightFill" />
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ActivityThread;