import React, { useState, useEffect } from 'react';
import { fetchData } from '../utils/apiUtils';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DrillDownModal } from './index';
import { Loading } from './common/index';
import { Indicator, MainScore } from './common/index';
import { Container, Row, Col } from 'react-bootstrap';

let refreshInterval;

function WidgetDashboard({ settings, dataUrl, basePath }) {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const filters = searchParams.get('filter')?.split(',') || [];

  const [data, setData] = useState([]);
  const [isVisible] = useState(true); //setIsVisible

  useEffect(() => {
    const fetchAll = async () => {
      if (settings.apiUrl !== null) {
        const fetchedData = await fetchData(dataUrl);
        if (fetchedData.data && JSON.stringify(fetchedData.data) !== JSON.stringify(data)) {
          setData(fetchedData.data);
        }
      }
    };
  
    const startRefreshInterval = () => {
      refreshInterval = setInterval(fetchAll, 60000);
    };
  
    const stopRefreshInterval = () => {
      clearInterval(refreshInterval);
    };

    fetchAll();
    if (isVisible) {
      startRefreshInterval();
    }
    return () => {
      stopRefreshInterval();
    };
  }, [settings.apiUrl, isVisible, data, dataUrl]);

  const handleClick = (next) => {
    navigate(`/${basePath}/${next}`);
  };

  const renderWidgets = () => {
    function DWidget({ item, handleClick }) {
      const { goTo, indicators } = item; // title

      const renderIndicator = (indicator, index) => {
        const goToFilter = `${basePath}/${goTo}?filter=`
        return <Indicator indicator={indicator} goTo={goToFilter} index={index} />
      };

      return (
        <div className="col gy-3">
          <div className="card p-0 mt-0 pt-4 pb-2">
            <Container>
              <Row className="mb-2">
                <Col xs={8}>
                  <MainScore item={item} goTo={`${basePath}/${item.goTo}`} />
                </Col>
                <Col xs={4}>
                  {renderIndicator(indicators[0])}
                  {renderIndicator(indicators[1])}
                </Col>
              </Row>
              <Row>
                {indicators.slice(2, 5).map((indicator, index) => (
                  <Col xs={4} key={`indicator-${index + 2}`}>
                    {renderIndicator(indicator)}
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      );
    }
    return data.map((item, index) => (
      <DWidget key={`${basePath}-widget-${index}`} item={item} handleClick={handleClick} />
    ));
  };

  const getSelectedItem = () => {
    return data.find(item => item.goTo === id) || data[0] || {};
  };

  if (data.length === 0) {
    return <div className="container-fluid h-100"><Loading /></div>;
  }

  return (
    <div className="widget-container row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 overflow-scroll p-2 h-100">
      {renderWidgets()}
      <DrillDownModal
        show={!!id}
        onHide={() => navigate(`/${basePath}`)}
        item={getSelectedItem()}
        filters={filters}
      />
    </div>
  );
}

export function DataSecurity({ settings }) {
  return <WidgetDashboard settings={settings} dataUrl="/Cybersecurity.JSON" basePath="security" />;
}

export function SystemHealth({ settings }) {
  return <WidgetDashboard settings={settings} dataUrl="/System_Health.JSON" basePath="health" />;
}