// LoadingScreen.js
import React from 'react';
import { Optimization } from './index';

function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center p-5 row text-center h-75">
      <Optimization level={0} size="large" />
      <h4 className="text-white">Loading...</h4>
    </div>
  );
}

export default Loading;