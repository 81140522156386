import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { signUp, loginUser } from '../utils/apiUtils';
import { useNavigate } from 'react-router-dom';

function SignIn({ settings }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (settings.authStrategy === 'local') {
      if (isSignUp) {
        // Sign up form submission
        if (password !== confirmPassword) {
          setError('Passwords do not match');
          return;
        }
        try {
          await signUp(username, password, settings.apiUrl);
          setIsSignUp(false);
        } catch (error) {
          setError(error.message);
        }
      } else {
        // Login form submission
        try {
          const data = await loginUser(username, password, settings.apiUrl);
          localStorage.setItem('token', data.token);
          const { redirectUrl } = data;
          navigate(redirectUrl)
        } catch (error) {
          setError(error.message);
        }
      }
    } else if (settings.authStrategy === 'ldap') {
      // LDAP login form submission
      try {
        const data = await loginUser(username, password, settings.apiUrl);
        localStorage.setItem('token', data.token);
        const { redirectUrl } = data;
        navigate(redirectUrl)
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={12} sm={8} md={6} lg={4}>
          <h2 className="text-center mb-4">
            {settings.authStrategy === 'local' && isSignUp ? 'Sign Up' : 'Login'}
          </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label>
                {settings.authStrategy === 'ldap' ? 'Username' : 'Email address'}
              </Form.Label>
              <Form.Control
                type={settings.authStrategy === 'ldap' ? 'text' : 'email'}
                placeholder={settings.authStrategy === 'ldap' ? 'Enter username' : 'Enter email'}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            {settings.authStrategy === 'local' && isSignUp && (
              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
            )}
            <Button variant="primary" type="submit" block>
              {settings.authStrategy === 'local' && isSignUp ? 'Sign Up' : 'Login'}
            </Button>
          </Form>
          {settings.authStrategy === 'local' && (
            <div className="text-center mt-3">
              {isSignUp ? (
                <p>
                  Already have an account?{' '}
                  <Button variant="link" onClick={() => setIsSignUp(false)}>
                    Login
                  </Button>
                </p>
              ) : (
                <p>
                  Don't have an account?{' '}
                  <Button variant="link" onClick={() => setIsSignUp(true)}>
                    Sign Up
                  </Button>
                </p>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col qxs={12} sm={8} md={6} lg={4} className="text-center">
          <Button 
            variant="warning" 
            onClick={() => navigate('/dashboard')} 
            size="lg" 
            className="w-100"
          >
            Demo
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
