import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ChartsLayout = ({ smallCharts, largeCharts }) => {
  const renderCharts = () => {
    let remainingSmall = [...smallCharts];
    let remainingLarge = [...largeCharts];
    const rows = [];
  
    const renderFullWidthLarge = () => {
      return remainingLarge.map((chart, index) => (
        <Row key={`full-large-${index}`}>
          <Col xs={12}>{chart}</Col>
        </Row>
      ));
    };
  
    const renderSmallCharts = (charts, colWidth) => {
      return (
        <Row>
          {charts.map((chart, index) => (
            <Col key={`small-${index}`} xs={colWidth}>
              {chart}
            </Col>
          ))}
        </Row>
      );
    };
  
    let isReversed = false; // Flag to track the order of charts
  
    if (remainingLarge.length > 0) {
      if (remainingSmall.length === 0) {
        rows.push(...renderFullWidthLarge());
      } else {
        while (remainingLarge.length > 0 && remainingSmall.length > 0) {
          if (remainingSmall.length === 3 && remainingLarge.length === 1) {
            rows.push(<Row key={`last-large-${rows.length}`}><Col xs={12}>{remainingLarge[0]}</Col></Row>);
            rows.push(<Row key={`last-small-${rows.length}`}>{renderSmallCharts(remainingSmall, 4)}</Row>);
            remainingSmall = [];
            remainingLarge = [];
            break;
          }
  
          const row = [];
          const smallToRender = remainingSmall.slice(0, Math.min(2, remainingSmall.length));
  
          if (!isReversed) {
            row.push(
              <Col key="small-column" xs={4}>
                <div className="h-100 d-flex flex-column justify-content-center">
                  {smallToRender.map((chart, index) => (
                    <div key={`small-${index}`}>
                      {chart}
                    </div>
                  ))}
                </div>
              </Col>
            );
  
            row.push(
              <Col key="large-column" xs={8}>
                {remainingLarge[0]}
              </Col>
            );
          } else {
            row.push(
              <Col key="large-column" xs={8}>
                {remainingLarge[0]}
              </Col>
            );
  
            row.push(
              <Col key="small-column" xs={4}>
                <div className="h-100 d-flex flex-column justify-content-center">
                  {smallToRender.map((chart, index) => (
                    <div key={`small-${index}`}>
                      {chart}
                    </div>
                  ))}
                </div>
              </Col>
            );
          }
  
          rows.push(<Row key={`mixed-row-${rows.length}`} className="h-100">{row}</Row>);
  
          remainingSmall = remainingSmall.slice(smallToRender.length);
          remainingLarge = remainingLarge.slice(1);
          isReversed = !isReversed; // Toggle the flag for the next row
        }
  
        if (remainingLarge.length > 0) {
          rows.push(...renderFullWidthLarge());
        }
      }
    }
  
    if (remainingSmall.length > 0) {
      const colWidth = remainingSmall.length === 1 || remainingSmall.length % 2 === 0 ? 6 : 4;
      rows.push(<Row key={`remaining-small-${rows.length}`}>{renderSmallCharts(remainingSmall, colWidth)}</Row>);
    }
  
    return rows;
  };

  return <Container>{renderCharts()}</Container>;
};

export default ChartsLayout;