import { useState, useEffect } from 'react';

export const usePersistedState = (key, initialState) => {
  const [state, setState] = useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : initialState;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export const formattedDateTime = unixTimestamp => {
  if (isNaN(unixTimestamp)) {
    unixTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
  }
  const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return formattedDate;
}

export const formattedDate = unixTimestamp => {
  return formattedDateTime(unixTimestamp).slice(0,10)
}

/**
 * Converts a date string in the format "yyyy-mm-dd" to a Unix timestamp
 * representing the start of the day (00:00:00) in the user's local time zone.
 * @param {string} dateString - The date string in the format "yyyy-mm-dd"
 * @returns {number} The Unix timestamp representing the start of the day in the local time zone
 */
export const dateStringToUnixStartOfDay = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);
  return Math.floor(date.getTime() / 1000);
}

/**
 * Converts a date string in the format "yyyy-mm-dd" to a Unix timestamp
 * representing the end of the day (23:59:59) in the user's local time zone.
 * @param {string} dateString - The date string in the format "yyyy-mm-dd"
 * @returns {number} The Unix timestamp representing the end of the day in the local time zone
 */
export const dateStringToUnixEndOfDay = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day, 23, 59, 59);
  return Math.floor(date.getTime() / 1000);
}
