export const colors = {
    // primary: "#0d6efd",
    primary: "#224466",
    secondary: "#6c757d",
    info: "#0dcaf0",
    dark: "#212529",
    success: "#198754",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#ffffee",
    darkBackground: '#2b2b2b',
    lightBackground: '#ffffff',
    darkBorder: '#444444',
    lightBorder: '#cccccc',
    darkHighlight: '#444444',
    lightHighlight: '#f0f0f0',
    darkText: '#ffffff',
    lightText: '#333333',
    darkPlaceholder: '#888888',
    lightPlaceholder: '#aaaaaa',
};
  
export const chartColors = [
    '#D32F2F', '#F57C00', '#FBC02D', '#7B1FA2', '#1976D2', '#00796B', '#2E7D32'
]