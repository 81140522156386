import { sendTrackingObjectToServer } from './apiUtils'
import Cookies from 'js-cookie';

let trackingObject = {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  id: '',
  start: null,
  end: null,
  events: []
};

const saveTrackingData = () => {
  localStorage.setItem('usageTracking', JSON.stringify(trackingObject));
};

export const trackEvent = (eventCode) => {
  trackingObject.events.push({
    event: eventCode,
    time: Math.floor(Date.now() / 1000) // Unix timestamp
  });
  // console.table(trackingObject.events)
};

const cleanupTracking = () => {
  saveTrackingData()
  window.removeEventListener('beforeunload', cleanupTracking);
};

// check if a new session should be started
const checkAndStartSession = async (apiUrl, initialEvent) => {
  const trackingSessionId = Cookies.get('connect.sid'); // should be trackingId
  // if(!trackingSessionId) return alert('please enable cookies to use this app')
  const storedData = localStorage.getItem('usageTracking');
  if (storedData) {
    trackingObject = JSON.parse(storedData);
  }
  if(true || trackingObject.id !== trackingSessionId){ // send previous session's tracking data
    try {
      // Send the previous session's tracking data to the server
      sendTrackingObjectToServer(trackingObject, apiUrl).then(res => {
        // console.log('do sth with res: ' + res)
      });
      // Clear the localStorage and start a new session tracking
      let now = Math.floor(Date.now() / 1000)
      trackingObject = {
        id: trackingSessionId || 'dev-mode. If you see that in production then the browser probably does not accept cookies',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        start: now,
        end: null,
        events: [
          {
            event: `start:${initialEvent}`,
            time: now,
          }
        ]
      };
      localStorage.removeItem('usageTracking');
      saveTrackingData();
    } catch (error) {
      console.error('Error sending tracking data to server:', error);
    }
  }
};

// call this in your App.js to initialize Tracking
export const initializeTracking = (apiUrl, initialEvent) => {
  checkAndStartSession(apiUrl, initialEvent)
  // document.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('beforeunload', cleanupTracking); // save data and remove eventlistener
};
