import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, InputGroup, Dropdown, DropdownButton, Table } from 'react-bootstrap';
import { loadTracker, updateIssueStatus } from '../utils/apiUtils.js';
import { formattedDate, formattedDateTime, dateStringToUnixEndOfDay, dateStringToUnixStartOfDay } from '../utils/utils.js';
import { OptimizeModal } from './index';
import { Loading, Risk } from './common/index'
import Icon from './common/Icons.js'
import Select, { components } from 'react-select';
import { colors } from '../styles/colors'
import { useTheme } from '../styles/Theme'

function FilterDropdown({ options, property }) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const { theme } = useTheme(); // Use the custom hook to get the current theme
  const isDarkMode = theme === 'dark';

  // Parse current URL parameters
  const params = new URLSearchParams(location.search);
  const currentValue = params.get(property);

  // Find the current option object based on the URL parameter
  const selectedOption = options.find(option => option.value === currentValue) || null;

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      params.set(property, selectedOption.value);
    } else {
      params.delete(property);
    }

    // Update the URL
    navigate({ search: params.toString() });
  };

  // Custom DropdownIndicator component
  const CustomDropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon name="Filter" /> 
      </components.DropdownIndicator>
    );
  };

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? colors.darkBackground : colors.lightBackground,
      borderColor: isDarkMode ? colors.darkBorder : colors.lightBorder,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? colors.darkBackground : colors.lightBackground,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? isDarkMode ? colors.darkHighlight : colors.lightHighlight
        : isDarkMode ? colors.darkBackground : colors.lightBackground,
      color: state.isFocused 
        ? isDarkMode ? colors.darkText : colors.lightText
        : isDarkMode ? colors.darkText : colors.lightText
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? colors.darkText : colors.lightText,
    }),
    input: (provided) => ({
      ...provided,
      color: isDarkMode ? colors.darkText : colors.lightText,
    }),
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      placeholder={`${property}`}
      isClearable
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      styles={selectStyles}
    />
  );
}

function FilterDate({ min, property, today }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState('');

  // Parse current URL parameters
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const currentValue = params.get(property);
    setSelectedDate(currentValue || '');
  }, [location.search, property]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    if (newDate) {
      params.set(property, newDate);
    } else {
      params.delete(property);
    }

    // Update the URL
    navigate({ search: params.toString() });
  };

  return (
    <div className="input-group">
      <label htmlFor={`tracker-${property}`} className="input-group-text">
        {property}
      </label>
      <input
        id={`tracker-${property}`}
        className="form-control"
        aria-label={property}
        min={formattedDate(min)}
        max={formattedDate(today)}
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </div>
  );
}

function Optimize({ settings }) {
  const [trackerData, setTrackerData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [serverOptions, setServerOptions] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [today, setToday] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const priority = searchParams.get('Priority');
  const server = searchParams.get('Server')
  const category = searchParams.get('Category')
  const status = searchParams.get('Status')
  const created = searchParams.get('Created') 
  const updated = searchParams.get('Updated')
  
  useEffect(() => {
    const fetchTrackerData = async () => {
      try {
        const { data } = await loadTracker();

        function sortByImpactLevel(array) {
          return array.sort((a, b) => b.Impact_Level - a.Impact_Level);
        }

        sortByImpactLevel(data);
  
        if (JSON.stringify(trackerData) !== JSON.stringify(data)) {
          setTrackerData(data);

          const currentDate = Math.floor(Date.now() / 1000);
          setToday(currentDate);
        }
      } catch (error) {
        console.error('Error loading tracker data:', error);
      }
    };
  
    if (settings.apiUrl !== null) {
      fetchTrackerData();
      const interval = setInterval(fetchTrackerData, 20000);
      
      // Cleanup function
      return () => clearInterval(interval);
    }
  }, [settings.apiUrl]);
  
  // Apply filters
  useEffect(() => {
    const filtered = trackerData.filter((item) => {
      const statusMatch = status === null || 
                          item.Status === status || 
                          (status === "Develop" && item.Status === undefined);
      
      if (status === "Develop" && item.Status === "Test") {
        console.log("Unexpected inclusion:", item);
      }

      if (status === "Develop" && statusMatch) {
        console.log("Item passed filter:", item.Status, item);
      }
    
      return (
        statusMatch &&
        (category === null || item.Category === category) &&
        (priority === null || item.Impact_Level === priority) &&
        (server === null || item.Server === server || item.servername === server) && 
        (created === null || formattedDate(item.Created_At) === created) && 
        (updated === null || formattedDate(item.Last_Updated) === updated) 
      );
    });
  
    setFilteredItems(filtered);
  }, [trackerData, status, category, priority, server, created, updated]);
  
  // Generate options based on filtered items
  useEffect(() => {
    const getUniqueOptions = (key, defaultValue = null) => {
      const uniqueValues = [...new Set(trackerData.map(item => {
        if (item[key] === undefined || item[key] === null) {
          return defaultValue;
        }
        return item[key];
      }))].filter(value => value !== null);
  
      return [
        ...uniqueValues.map(value => ({ value, label: value }))
      ];
    };
  
    setCategoryOptions(getUniqueOptions('Category'));
    setStatusOptions(getUniqueOptions('Status', 'Develop'));
    setPriorityOptions(getUniqueOptions('Impact_Level'));
    setServerOptions(getUniqueOptions('servername'));
  }, [filteredItems]);

  // enables loading component from deeplink to specific item 
  useEffect(() => { 
    if (id) {
      if(!trackerData) return
      const selectedItem = trackerData.find((item) => item.Id.toString() === id);
      if (selectedItem) {
        setSelectedItem(selectedItem);
      }
    }
  }, [id, trackerData]);
      
  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate(`/optimize/${item.Id}`);
  };

  const closeDetailView = () => {
    setSelectedItem(null);
    navigate('/optimize');
  };

  const updateIssueStatusHandler = (item, Status) => {
    let nextTracker = JSON.parse(JSON.stringify(trackerData));
    nextTracker.forEach((issue, i) => {
      if (issue.Id === item.Id) {
        issue.Status = Status;
        issue.Last_Updated = Math.floor(Date.now() / 1000);
      }
    });
    setTrackerData(nextTracker);
    updateIssueStatus(item.Id, Status, settings.apiUrl);
  };

  const selectStatus = (item) => {
    return (
      <div className="input-group mb-3">
        <select
          className="form-select"
          id={`tracker-detail-select-${item.Id}`}
          onChange={(e) => updateIssueStatusHandler(item, e.target.value)}
          value={item.Status}
        >
          <option value="Develop">Develop</option>
          <option value="Test">Test</option>
          <option value="Deploy">Deploy</option>
          <option value="Observe">Observe</option>
          <option value="Closed">Closed</option>
        </select>
      </div>
    );
  };

  let items = []
  
  items = filteredItems;

  if (search !== undefined && search !== '') {
    items = items.filter((item) => {
      let rootCauseAnal = item.Root_Cause_Analysis.split('->');
      let recommendation = rootCauseAnal.pop();
      let theIssue = rootCauseAnal.pop(); 
      let combined = item.Category + item.Server + item.servername + theIssue + recommendation;
      return combined.toLowerCase().includes(search.toLowerCase());
    });
  }
  
  let minCreated = today;
  let minUpdated = today;
  for (let i = 0; i < trackerData.length; i++) {
    let item = trackerData[i];
    if (item.Created_At < minCreated) minCreated = item.Created_At; 
    if (item.Last_Updated < minUpdated) minUpdated = item.Last_Updated; 
  }

  let displayItems = items.map((item, index) => {
    let { Category, Server, servername, Impact_Level, Created_At, Last_Updated, Root_Cause_Analysis } = item; // Status
    let Issue = Root_Cause_Analysis[0].Name || "" //Root_Cause_Analysis ? Root_Cause_Analysis.pop() : '';

    let risk_level = 0;
    if(Impact_Level > 0.2) risk_level = 1;
    if(Impact_Level > 0.6) risk_level = 2;
    return (
      <tr key={`tracker-${index}`}>
        <td onClick={() => handleItemClick(item)}>
          <div style={{width: "100px"}}>
          <Risk level={risk_level} />
          </div>
        </td>
        <td className="w-25">
          <div
            className="solution p-2 position-relative cursor-copy"
            onClick={(e) => {
              navigator.clipboard.writeText(Issue);
              window.getSelection().selectAllChildren(e.target);
            }}
          >
            {Issue}
            {Issue && <Icon name="Files" className="position-absolute bottom-0 end-0 text-primary" />}
          </div>
        </td>
        <td className="d-none d-lg-table-cell" onClick={() => handleItemClick(item)}>{Server || servername}</td>
        <td className="d-none d-md-table-cell" onClick={() => handleItemClick(item)}>{Category}</td>
        <td className="d-none d-lg-table-cell" onClick={() => handleItemClick(item)}>{formattedDateTime(Created_At)}</td>
        <td className="d-none d-lg-table-cell" onClick={() => handleItemClick(item)}>{formattedDateTime(Last_Updated)}</td>
        <td>{selectStatus(item)}</td>
      </tr>
    );
  });

  return (
    <div className="row h-100">
      <div className="w-100 h-100 overflow-auto">
        <Table striped hover className="border-separate">
          <thead className="position-sticky" style={{top: "0px", zIndex: 10, backGroundColor: "#333"}}>
            <tr>
              <th>
                <FilterDropdown options={priorityOptions} property="Priority" />
              </th>
              <th>
                
                  <input
                    className="form-control"
                    id="tracker-searchfield"
                    placeholder="Issue: Start typing to filter..."
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <Icon name="XSquare"
                    className="fs-4 float-end opacity-50 me-2"
                    style={{ marginTop: '-30px'}}
                    onClick={() => setSearch('')}
                  />
                
              </th>
              <th className="d-none d-lg-table-cell">
                <FilterDropdown options={serverOptions} property="Server" />
              </th>
              <th className="d-none d-md-table-cell">
                <FilterDropdown options={categoryOptions} property="Category" />
              </th>
              <th className="d-none d-lg-table-cell">
                <FilterDate min={minCreated} property="Created" today={today} />
              </th>
              <th className="d-none d-lg-table-cell">
                <FilterDate min={minUpdated} property="Updated" today={today} />
              </th>
              <th>
                <FilterDropdown options={statusOptions} property="Status" />
              </th>
            </tr>
          </thead>
          <tbody>
            { displayItems }
          </tbody>
        </Table>
        {displayItems.length === 0 && <Loading /> }
      </div>
      {selectedItem && (
        <OptimizeModal
          show={true}
          onHide={closeDetailView}
          item={selectedItem}
          updateTracker={updateIssueStatusHandler}
        />
      )}
    </div>
  );
}

export default Optimize;
