import React, { useState, useEffect, useRef } from 'react';
import Globe from 'react-globe.gl';

const DynamicEarthGlobe = () => {
  const [globeImageUrl, setGlobeImageUrl] = useState('');
  const canvasRef = useRef(null);

  const calculateLongitudes = () => {
    const date = new Date();    
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const sunLongitude = 180 - (hours * 15 + minutes / 4);
    return { sunLongitude };
  };

  const createDayNightMap = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 1024;
    const height = 512;
    canvas.width = width;
    canvas.height = height;

    const { sunLongitude } = calculateLongitudes();

    const dayImage = new Image();
    const nightImage = new Image();

    dayImage.src = '/img/earth-blue-marble.jpg';
    nightImage.src = '/img/earth-night.jpg';

    Promise.all([
      new Promise(resolve => { dayImage.onload = resolve; }),
      new Promise(resolve => { nightImage.onload = resolve; })
    ]).then(() => {
      // Draw the day image as base
      ctx.drawImage(nightImage, 0, 0, width, height);

      // Calculate tilt angle based on day of year
      const date = new Date();
      const dayOfYear = Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 86400000);
      const tiltAngle = Math.sin((dayOfYear / 365 - 0.22) * 2 * Math.PI) * 23.5; // Max tilt of 23.5 degrees

      // Create a clip path for the night side
      ctx.save();
      ctx.beginPath();
      for (let x = 0; x < width; x++) {
        const longitude = (x / width) * 360 - 180;
        
        // Calculate the latitude where the terminator intersects this longitude
        const terminatorLatitude = Math.atan(-Math.cos((longitude - sunLongitude) * Math.PI / 180) / Math.tan(tiltAngle * Math.PI / 180)) * 180 / Math.PI;
        
        // Convert latitude to y-coordinate (latitude 90 = y 0, latitude -90 = y height)
        const y = (90 - terminatorLatitude) * (height / 180);

        ctx.lineTo(x, y);
      }
      ctx.lineTo(width, 0);
      ctx.lineTo(0, 0);
      ctx.closePath();
      ctx.clip();

      // Draw the day image (only visible in the clipped area)
      ctx.drawImage(dayImage, 0, 0, width, height);
      ctx.restore();

      // Set the globe image URL
      setGlobeImageUrl(canvas.toDataURL());
    });
  };

  useEffect(() => {
    createDayNightMap();
    const interval = setInterval(createDayNightMap, 10 * 60 * 1000); // Update every 10 minutes
    return () => clearInterval(interval);
  });


  let monitorData = [
        {
          "city": "Berlin",
          "coordinates": [13.4050, 52.5200],
          "servers": [
            {
              "name": "Berlin-Server-1",
              "system_health": 0,
              "cybersecurity": 0,
              "optimization": 0
            }
          ]
        },
        {
          "city": "Punta del Este",
          "coordinates": [-54.9338, -34.9638],
          "servers": [
            {
              "name": "PuntaDelEste-Server-1",
              "system_health": 0,
              "cybersecurity": 0,
              "optimization": 0
            }
          ]
        },
        {
          "city": "Tel Aviv",
          "coordinates": [34.7818, 32.0853],
          "servers": [
            {
              "name": "TelAviv-Server-1",
              "system_health": 2,
              "cybersecurity": 1,
              "optimization": 2
            }
          ]
        },
        {
          "city": "Cape Town",
          "coordinates": [18.4241, -33.9249],
          "servers": [
            {
              "name": "CapeTown-Server-1",
              "system_health": 0,
              "cybersecurity": 0,
              "optimization": 0
            }
          ]
        },
        {
          "city": "Delhi",
          "coordinates": [77.2090, 28.6139],
          "servers": [
            {
              "name": "Delhi-Server-1",
              "system_health": 0,
              "cybersecurity": 0,
              "optimization": 0
            }
          ]
        },
        {
          "city": "Tokyo",
          "coordinates": [139.6503, 35.6762],
          "servers": [
            {
              "name": "Tokyo-Server-1",
              "system_health": 0,
              "cybersecurity": 0,
              "optimization": 0
            }
          ]
        },
        {
          "city": "Dubai",
          "coordinates": [55.2708, 25.2048],
          "servers": [
            {
              "name": "Dubai-Server-1",
              "system_health": 0,
              "cybersecurity": 0,
              "optimization": 0
            }
          ]
        }
      ];

  const htmlElementsData = monitorData.map(city => ({
    lat: city.coordinates[1],
    lng: city.coordinates[0],
    name: city.city,
    servers: city.servers
  }));

  const arcs = [];
  for (let i = 0; i < monitorData.length; i++) {
    for (let j = i + 1; j < monitorData.length; j++) {
      arcs.push({
        startLat: monitorData[i].coordinates[1],
        startLng: monitorData[i].coordinates[0],
        endLat: monitorData[j].coordinates[1],
        endLng: monitorData[j].coordinates[0],
        color: 'white'  // semi-transparent orange
      });
    }
  }

  const markerSvg = `<div style="width:40px;margin: 0 auto; margin-top: 5px; display: block;">
  <svg viewBox="-4 0 36 36">
    <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
    <circle fill="black" cx="14" cy="14" r="7"></circle>
  </svg></div>
`;

const createHtmlElement = (d) => {
    let scoreText = ['Excellent', 'Fair', 'Critical'];
    let scoreColor = ['#198754', '#ffc107', '#dc3545'];
    let maxScore = 0;
    let servers = d.servers.map(s => {
        let serverMax = Math.max(s.system_health, s.cybersecurity, s.optimization)
        if (serverMax > maxScore) maxScore = serverMax;
        return `<tr>
                <td>${s.name}</td>
                <td style="text-align:center;color:${scoreColor[s.system_health]}">${scoreText[s.system_health]}</td>
                <td style="text-align:center;color:${scoreColor[s.cybersecurity]}">${scoreText[s.cybersecurity]}</td>
                <td style="text-align:center;color:${scoreColor[s.optimization]}">${scoreText[s.optimization]}</td>
            </tr>`;
    }).join('');
    const el = document.createElement('div');
    el.innerHTML = `<div onclick="window.location.href='/dashboard';" style="text-align:center; padding: 10px; margin-top: -50px; border-radius: 25px; width: 100%; height: 100%;"><h5 style="color: white; margin: 0;">${d.name}</h5>${markerSvg}</div>`;
    el.style.color = scoreColor[maxScore]
    el.style.width = `100px`;
    el.style.height = `100px`;
    el.style.pointerEvents = 'auto';
    el.style.cursor = 'pointer';
    
    el.onmouseover = () => {
        el.style.transform = 'scale(1.3)';
        const tooltip = document.createElement('div');
        tooltip.innerHTML = `
            <h4>${d.name}</h4>
            <br>
            <table>
                <thead>
                    <th>Server Name &nbsp;</th><th>System Health &nbsp;</th><th>Data Shield &nbsp;</th><th>Optimization &nbsp;</th>
                </thead>
                <tbody>
                    ${servers}
                </tbody>
            </table>
        `;
        tooltip.style.position = 'absolute';
        tooltip.style.top = '100%';
        tooltip.style.left = '50%';
        tooltip.style.transform = 'translateX(-50%)';
        tooltip.style.backgroundColor = 'rgba(0,0,0,0.8)';
        tooltip.style.color = 'white';
        tooltip.style.padding = '5px';
        tooltip.style.borderRadius = '3px';
        tooltip.style.whiteSpace = 'nowrap';
        el.appendChild(tooltip);
    };

    el.onmouseout = () => {
        el.style.transform = 'scale(1)';
        el.removeChild(el.lastChild);
    };

    return el;
};

  return (
    <div className="height-minus-header w-100 d-flex justify-content-center align-items-center overflow-hidden">
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <Globe
        width={3000}
        height={700}
        globeImageUrl={globeImageUrl}
        backgroundImageUrl="/img/night-sky.png"
        atmosphereColor="#CBF"
        atmosphereAltitude={0.25}
        showAtmosphere={true}
        htmlElementsData={htmlElementsData}
        htmlElement={createHtmlElement}
      />
    </div>
  );
};

export default DynamicEarthGlobe;