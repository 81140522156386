import {
  Activity,
  App,
  Arrow90degRight,
  ArrowDownUp,
  ArrowLeftRight,
  ArrowRight,
  ArrowUpCircleFill,
  Award,
  Bandaid,
  BellFill,
  Binoculars,
  Box,
  BoxArrowInUpRight,
  Bricks,
  Bug,
  Bullseye,
  Calendar,
  CalendarCheck,
  CaretRightFill,
  Check2Circle,
  CheckCircle,
  CheckSquare,
  CheckSquareFill,
  Clipboard,
  ClipboardCheck,
  Clock,
  ClockHistory,
  CloudArrowUp,
  CloudUpload,
  CodeSlash,
  CodeSquare,
  Collection,
  Cpu,
  Database,
  Diagram2,
  Diagram3,
  Display,
  DoorOpen,
  Envelope,
  EnvelopeFill,
  ExclamationOctagon,
  ExclamationTriangle,
  ExclamationTriangleFill,
  Eye,
  EyeFill,
  EyeSlash,
  FileEarmarkBarGraph,
  FileEarmarkCheck,
  FileEarmarkCode,
  FileEarmarkLock,
  FileEarmarkMedical,
  FileEarmarkRuled,
  FileEarmarkText,
  FileEarmarkX,
  FileEarmarkZip,
  Files,
  Filter,
  Fingerprint,
  Folder,
  Folder2,
  FolderCheck,
  Funnel,
  Gear,
  GearWideConnected,
  Globe,
  GraphUp,
  Grid3x3,
  Hdd,
  HddFill,
  HddStack,
  Headset,
  Hourglass,
  Info,
  InfoCircle,
  JournalCheck,
  JournalText,
  Kanban,
  Key,
  Layers,
  Lightning,
  ListCheck,
  ListOl,
  ListUl,
  Lock,
  LockFill,
  Memory,
  Mortarboard,
  PatchExclamation,
  Pause,
  Pc,
  PencilSquare,
  People,
  PeopleFill,
  Person,
  PersonBadge,
  PersonBoundingBox,
  PersonCheck,
  PersonGear,
  PersonX,
  PieChart,
  PlayBtn,
  Radar,
  Search,
  Server,
  Shield,
  ShieldCheck,
  ShieldExclamation,
  ShieldFillCheck,
  ShieldLock,
  ShieldShaded,
  ShieldX,
  Shuffle,
  Signpost,
  Speedometer,
  Speedometer2,
  Stopwatch,
  Table,
  Tags,
  Terminal,
  ThreeDotsVertical,
  Tools,
  Usb,
  UsbSymbol,
  Wifi,
  Window,
  Windows,
  XSquare,
  XSquareFill
} from 'react-bootstrap-icons';

const IconMap = {
  Activity,
  App,
  Arrow90degRight,
  ArrowDownUp,
  ArrowLeftRight,
  ArrowRight,
  ArrowUpCircleFill,
  Award,
  Bandaid,
  BellFill,
  Binoculars,
  Box,
  BoxArrowInUpRight,
  Bricks,
  Bug,
  Bullseye,
  Calendar,
  CalendarCheck,
  CaretRightFill,
  Check2Circle,
  CheckCircle,
  CheckSquare,
  CheckSquareFill,
  Clipboard,
  ClipboardCheck,
  Clock,
  ClockHistory,
  CloudArrowUp,
  CloudUpload,
  CodeSlash,
  CodeSquare,
  Collection,
  Cpu,
  Database,
  Diagram2,
  Diagram3,
  Display,
  DoorOpen,
  Envelope,
  EnvelopeFill,
  ExclamationOctagon,
  ExclamationTriangle,
  ExclamationTriangleFill,
  Eye,
  EyeFill,
  EyeSlash,
  FileEarmarkBarGraph,
  FileEarmarkCheck,
  FileEarmarkCode,
  FileEarmarkLock,
  FileEarmarkMedical,
  FileEarmarkRuled,
  FileEarmarkText,
  FileEarmarkX,
  FileEarmarkZip,
  Files,
  Filter,
  Fingerprint,
  Folder,
  Folder2,
  FolderCheck,
  Funnel,
  Gear,
  GearWideConnected,
  Globe,
  GraphUp,
  Grid3x3,
  Hdd,
  HddFill,
  HddStack,
  Headset,
  Hourglass,
  Info,
  InfoCircle,
  JournalCheck,
  JournalText,
  Kanban,
  Key,
  Layers,
  Lightning,
  ListCheck,
  ListOl,
  ListUl,
  Lock,
  LockFill,
  Memory,
  Mortarboard,
  PatchExclamation,
  Pause,
  Pc,
  PencilSquare,
  People,
  PeopleFill,
  Person,
  PersonBadge,
  PersonBoundingBox,
  PersonCheck,
  PersonGear,
  PersonX,
  PieChart,
  PlayBtn,
  Radar,
  Search,
  Server,
  Shield,
  ShieldCheck,
  ShieldExclamation,
  ShieldFillCheck,
  ShieldLock,
  ShieldShaded,
  ShieldX,
  Shuffle,
  Signpost,
  Speedometer,
  Speedometer2,
  Stopwatch,
  Table,
  Tags,
  Terminal,
  ThreeDotsVertical,
  Tools,
  Usb,
  UsbSymbol,
  Wifi,
  Window,
  Windows,
  XSquare,
  XSquareFill
};

const Icon = ({ name, ...props }) => {
  const IconComponent = IconMap[name];
  return IconComponent ? <IconComponent {...props} /> : <div>{name} Icon missing!</div>;
};

export default Icon;