import React, {useMemo} from 'react';
import Chart from 'react-apexcharts';
import GaugeChart from 'react-gauge-chart';
import TrafficSignal from 'react-trafficsignal';
import { colors, chartColors } from '../../styles/colors';
import Icon from './Icons'
import { Container } from 'react-bootstrap'
import { Heartbeat, Radar, Cube } from './Animations'
import { useTheme } from '../../styles/Theme'

const validateSeriesData = (series) => {
  return Array.isArray(series) && series.every(item => 
    typeof item === 'object' && 
    typeof item.name === 'string' && 
    Array.isArray(item.data) && 
    item.data.every(value => typeof value === 'number')
  );
};

const validateSingleSeriesData = (series) => {
  return Array.isArray(series) && series.every(value => typeof value === 'number');
};

const validateCategoriesData = (categories) => {
  return Array.isArray(categories) && categories.every(item => 
    typeof item === 'string' || typeof item === 'number'
  );
};

// Centralized chart options
const useCommonChartOptions = () => {
  const {theme} = useTheme();

  return useMemo(() => ({
    chart: {
      toolbar: {
        tools: {
          download: false
        },
      },
      animations: {
        enabled: false
      }
    },
    tooltip: {
      theme,
    },
    legend: {
      labels: {
        colors: theme === 'dark' ? '#ddd' : '#333',
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: theme === 'dark' ? '#ddd' : '#333',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === 'dark' ? '#ddd' : '#333',
        },
      },
    },
  }), [theme]);
};

const styledIcon = (iconName, color, text=null) => {
  return (
    <Container className="d-flex justify-content-center align-items-center h-100" style={{ maxWidth: '300px' }}>
      <div className="text-center">
        <Icon 
          name={iconName}
          className="mb-3"
          style={{
            fontSize: '7cqmax',
            color: color
          }}
        />
        <div>{text}</div>
      </div>
    </Container>
  )
}

export const Alert = ({text}) => {
    const iconName="ExclamationTriangleFill"
    const color = colors.danger
    return styledIcon(iconName, color, text)
}

export const Area = ({series, categories, text=null}) => {
    const commonOptions = useCommonChartOptions();

    const options = useMemo(() => ({
      ...commonOptions,
      chart: {
          ...commonOptions.chart,
          type: 'area',
          id: 'apexchart-example',
          zoom: {
              type: 'x',
              enabled: false,
              autoScaleYaxis: true
          },
      },
      dataLabels: {
          enabled: false
      },
      stroke: {
          curve: 'smooth'
      },
      xaxis: {
          ...commonOptions.xaxis,
          categories
      },
      colors: chartColors
    }), [commonOptions, categories]);

    if(!validateSeriesData(series) || !validateCategoriesData(categories)){
      console.log('Area chart has invalid series or categories data')
      return null
    }
    
    return (
    <div style={{width: '100%', height: "100%", paddingTop: "10px"}}>
        <div id="chart">
        <Chart options={options} series={series} type="area" />
        </div>
        {text}
    </div>
    );
};

export const Bar = ({series, categories, text=null}) => {
  const commonOptions = useCommonChartOptions();
  const options = useMemo(() => ({
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      id: 'apexchart-example',
    },
    colors: chartColors,
    xaxis: {
      ...commonOptions.xaxis,
      categories
    },
}), [commonOptions, categories]);

  if(!validateSeriesData(series) || !validateCategoriesData(categories)){
    console.log('Bar chart has invalid series or categories data')
    return null
  }
    
  return (
    <div style={{width: '100%', height: "100%", paddingTop: "10px"}}>
      <div id="chart">
        <Chart options={options} series={series} type="bar" />
        {text}
      </div>
    </div>
  );
};

export const Column = ({series, categories, text=null}) => {
  const commonOptions = useCommonChartOptions();
  const options = useMemo(() => ({
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      id: 'column-chart',
    },
    colors: chartColors,
    xaxis: {
      ...commonOptions.xaxis,
      categories: [""]
    },
  }), [commonOptions]);

  if(!validateSingleSeriesData(series) || !validateCategoriesData(categories)){
    console.log('Bar chart has invalid series or categories data')
    return null
  }

  let newSeries = categories.map((category, index) => ({
    name: category,
    data: [series[index]]
  }));

  return (
    <div style={{width: '100%', height: "100%", paddingTop: "10px"}}>
      <div id="chart">
        <Chart options={options} series={newSeries} type="bar" />
      </div>
      {text}
    </div>
  );
};

export const Gauge = ({percent, text="Extimated Impact"}) => {
    return (
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          width: '100%',
          maxWidth: '140px', // Adjust this value to control the overall size
          margin: '0 auto'
        }}>
          <div style={{ width: '100%' }}>
            <GaugeChart 
              animate={false} 
              colors={[colors.success, colors.warning, colors.danger]} 
              hideText
              percent={percent / 100}
              nrOfLevels={3}
              arcWidth={0.2} // Adjust this to make the gauge thinner or thicker
            />
          </div>
          <div style={{ 
            textAlign: 'center', 
            marginTop: '5px' // Adjust this value to control space between gauge and text
          }}>
            {text}
          </div>
        </div>
      )
    }
  
export const Health = ({ level }) => {
  return <Heartbeat level={level} />
};

export const Insecure = ({text}) => {
  const iconName = "ShieldX"
  const color = colors.warning
  return styledIcon(iconName, color, text)
}

export const Line = ({series, categories, text=null}) => {
    const commonOptions = useCommonChartOptions();
    const options = useMemo(() => ({
      ...commonOptions,
      chart: {
        ...commonOptions.chart,
        type: 'line',
        zoom: {
          type: 'x',
          enabled: false,
          autoScaleYaxis: true
        },
      },
      colors: chartColors,
      xaxis: {
        ...commonOptions.xaxis,
        categories: categories
      },
      stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'round',
          width: 3
      },
  }), [commonOptions, categories]);

  if(!validateSeriesData(series) || !validateCategoriesData(categories)){
    console.log('Line chart has invalid series or categories data')
    return null
  }

  let chart = <div></div>

  chart = <div style={{padding: "-10px", margin: "-10px"}}>
            <Chart options={options} series={series} type="line" />
            {text}
        </div>

  return chart;
};

export const Optimization = ({ level, size = 'medium' }) => {
  return <Cube level={level} size={size} />
};

export const Pie = ({ series, labels, text=null }) => {
  const commonOptions = useCommonChartOptions();
  const options = useMemo(() => ({
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      type: 'pie',
    },
    legend: {
      ...commonOptions.legend,
      floating: false,
      position: 'right',
    },
    labels: labels,
    colors: chartColors
}), [commonOptions, labels]);
  
  if(!validateSingleSeriesData(series) || !validateCategoriesData(labels)){
    console.log('Pie chart has invalid series or labels data')
    return null
  }

  return (
    <div style={{width: '100%', height: "100%", paddingTop: "10px", paddingBottom: "20px"}}>
      <div id="chart" style={{ height: '100%'}}>
        <Chart options={options} series={series} type="pie" height='450' />
      </div>
      {text}
    </div>
  );
};

export const MultiRadial = ({ series, labels, text = 'TOTAL' }) => {
  const commonOptions = useCommonChartOptions();
  
  const options = useMemo(() => ({
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      height: 280,
      type: "radialBar",
    },
    colors: chartColors,
    plotOptions: {
      radialBar: {
        dataLabels: {
          total: {
            show: true,
            label: text,
            color: commonOptions.legend.labels.colors
          },
          value: {
            color: commonOptions.legend.labels.colors
          },
        }
      }
    },
    labels: labels
  }), [commonOptions, labels, text]);

  if (!validateSingleSeriesData(series) || !validateCategoriesData(labels)) {
    console.log('MultiRadial chart has invalid series or labels data');
    return null;
  }

  let percentSeries = series.map(number => number * 100);

  return (
    <div style={{ width: '100%', height: "100%", paddingTop: "10px" }}>
      <div id="chart">
        <Chart options={options} series={percentSeries} type="radialBar" height={280} />
      </div>
    </div>
  );
};

export const Radial = ({ percent, label = "Progress" }) => {
  const commonOptions = useCommonChartOptions();
  const { isDarkMode } = useTheme();
  
  const options = useMemo(() => ({
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      height: 280,
      type: "radialBar",
    },
    colors: [chartColors[0]],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: commonOptions.legend.labels.colors,
            fontSize: "13px"
          },
          value: {
            color: commonOptions.legend.labels.colors,
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: [chartColors[2]],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: [label]
  }), [commonOptions, label, isDarkMode]);

  if (typeof percent !== 'number' || percent < 0 || percent > 1) {
    console.log('Radial chart has invalid percent value');
    return null;
  }

  if (typeof label !== 'string') {
    console.log('Radial chart has invalid label');
    return null;
  }

  return (
    <div style={{ width: '100%', height: "100%", paddingTop: "10px" }}>
      <div id="chart">
        <Chart options={options} series={[percent * 100]} type="radialBar" height={280} />
      </div>
    </div>
  );
};

export const Risk = ({ level, text=null }) => {
  const defaultText = {
    0: "Low Risk",
    1: "Medium Risk",
    2: "High Risk",
  };
  const statusMap = {
    0: "BBG",
    1: "BAB",
    2: "RBB",
    default: "RAG",
  };
  
  const finalText = text || defaultText[level] || "Unknown Risk";
  const status = statusMap[level] || statusMap.default;
  return (
    <div style={{ 
      display: "flex", 
      alignItems: "center", 
      justifyContent: "center",
      margin: "10px 0",
      width: "100%"
    }}>
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        maxWidth: "80%", // Limit the width of the content
      }}>
        <div style={{ 
          marginRight: "10px", 
          width: "25%", // 25% of the text width
          minWidth: "20px", // Ensure a minimum size
        }}>
          <TrafficSignal status={status} style={{ width: "100%" }} />
        </div>
        <div style={{ flex: 1 }}>{finalText}</div>
      </div>
    </div>
  );
};

export const Unsafe = ({text}) => {
    const iconName = "XSquareFill"
    const color = colors.danger 
    return styledIcon(iconName, color, text)
}

export const Warning = ({text}) => {
    const iconName = "ExclamationTriangleFill"
    const color = colors.warning
    return styledIcon(iconName, color, text)
}

export const Safe = ({text}) => {
    const iconName = "CheckSquareFill"
    const color = colors.success
    return styledIcon(iconName, color, text)
};

export const Secure = ({text}) => {
    const iconName = "ShieldFillCheck"
    const color = colors.success
    return styledIcon(iconName, color, text)
}

export const Security = ({level}) => {
  return <Radar level={level} />
};

export const SolutionRisk = ({level}) => {
  const defaultText = {
    0: "Implementing this solution carries little risk.",
    1: "Implementing this solution carries risk.",
    2: "Careful. Implementing this solution carries significant risks. Experts only.",
  };
  const text = defaultText[level]
  if (level === 0) return Safe({text})
  if (level === 1) return Warning({text})
  return Alert({text})
}