import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, Navigate } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { loadSettings } from './utils/apiUtils'; // logoutUser
import { trackEvent, initializeTracking } from './utils/usageTracking';
import { Admin, Dashboard, SystemHealth, DataSecurity, Optimize, SignIn } from './components/index'
import logo from './logo.png';
import './styles/custom.scss';
import Icon from './components/common/Icons'
import Globe from './components/Globe'
import { colors } from './styles/colors'
import { useTheme, ThemeToggle } from './styles/Theme'

// const history = createBrowserHistory();

// history.listen(({ action, location }) => {
//   // Your tracking logic here
//   console.log(`Navigation event: ${action} to ${location.pathname}`);
// });

function App() {
  const [settings, setSettings] = useState({
    modules: { Dashboard: true, Tracker: true, SystemHealth: true, DataSecurity: true },
    monitor: [],
    sendUsage: true,
    apiUrl: null
  });

  const { theme, toggleTheme } = useTheme();
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const refreshSettings = async () => {
      const { data } = await loadSettings();
      if (process.env.NODE_ENV === 'production') data.apiUrl = '/api';
      setSettings(data);
      // if (JSON.stringify(data) !== JSON.stringify(settings)) {
      //   console.log('settings changed')

      // }
    };
    let refreshSettingsInterval;

    const startRefreshInterval = () => {
      refreshSettingsInterval = setInterval(refreshSettings, 60000);
    };
  
    const stopRefreshInterval = () => {
      clearInterval(refreshSettingsInterval);
    };

    const fetchSettings = async () => {
      const { data, fromCache } = await loadSettings();
      if (data && data.apiUrl && !fromCache) setSettings(data);
      initializeTracking(data.apiUrl, getFrom());
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        startRefreshInterval();
        trackEvent(`gainVisibility:${getFrom()}`);
      } else {
        stopRefreshInterval();
        trackEvent(`loseVisibility:${getFrom()}`);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    fetchSettings();

    if (document.visibilityState === 'visible') {
      startRefreshInterval();
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopRefreshInterval();
    };
  }, []);

  const getFrom = () => {
    const pathname = window.location.pathname;
    if (pathname.includes('dashboard')) return 'dashboard';
    if (pathname.includes('health')) return 'health';
    if (pathname.includes('security')) return 'security';
    if (pathname.includes('optimize')) return 'optimize';
    if (pathname.includes('admin')) return 'admin';
    if (pathname.includes('executive')) return 'executive';
    return 'fromUnknown';
  };

  const MenuItem = ({ to, icon, label, onClick }) => {
    const getIcon = () => {
      if (icon) {
        return <Icon name={icon} className="me-2" />;
      }
      if (logo) {
        return <img src={logo} className="me-2 logo" alt={label} width="18" />;
      }
      return null;
    };
  
    return (
      <NavLink
        to={to}
        className={({ isActive }) => `
          menuItem col-6 col-md-3 col-lg-3 col-xl-2 text-decoration-none py-2 px-3
          ${isActive ? 'text-white fw-bold' : 'text-light'}
        `}
        onClick={() => onClick(label)}
      >
        {getIcon()}
        {label}
      </NavLink>
    );
  };  

  const isAuthenticated = () => true; // Simplified for this example

  const menuTracking = (to) => {
    console.log('clicked on ' + to)
    const from = getFrom();
    if (from !== to) {
      trackEvent(`view:${to}_from:${from}_click:TopMenu`);
    }
  };

  const renderMenu = () => {
    const { modules } = settings;

    return (
      <div className="row g-0 w-100">
        {modules.Dashboard && (
          <MenuItem
            to="/"
            icon={"Speedometer"}
            label="Dashboard"
            onClick={menuTracking}
          />
        )}
        {modules.Tracker && (
          <MenuItem
            to="/optimize"
            label="Optimize"
            onClick={menuTracking}
          />
        )}
        {modules.DataSecurity && (
          <MenuItem
            to="/security"
            icon={"Radar"}
            label="Security"
            onClick={menuTracking}
          />
        )}
        {modules.SystemHealth && (
          <MenuItem
            to="/health"
            icon={"Activity"}
            label="Health"
            onClick={menuTracking}
          />
        )}
      </div>
    );
  };

  const jokes = ["Why do programmers prefer dark mode? Because light attracts bugs!",
    "How many DBAs does it take to change a light bulb? None, it's a hardware problem.",
    "A SQL query walks into a bar, walks up to two tables and asks, \"Can I join you?\"",
    "Why did the sysadmin quit his job? He couldn't handle the root of the problem.",
    "What's a DBA's favorite dance? The index shuffle.",
    "Why was the sysadmin late to work? He got stuck in a recursive loop on his way.",
    "How does a DBA drop a database in production? Very carefully.",
    "It feels like it's me VS Code.", 
    "Spanish github charts this week dominated by \"I took a pull in Ibiza.\" (to show Avici I was coding)"
  ]
  let tickerText = jokes.join(" 🚦")
  return (
    <Router basename="/">
      <div className="container-fluid p-0 overflow-hidden d-flex flex-column min-vh-100">
        <div className="row g-0 flex-shrink-0 m-0 header">
          <div className="col-1 d-none d-lg-flex align-items-center justify-content-center">
            <NavLink to="executive">
              <img src={logo} className="p-1 logo" style={{width: "70px"}} alt="UpTime" />
            </NavLink>
          </div>
          <div className="col-12 col-lg-10 sticky-top">
            <div className="d-flex align-items-center flex-grow-1">
                <h2 className="mb-0 text-shadow">
                  <span style={{ color: colors.danger }}>db</span>
                  <span style={{ color: colors.warning }}>Expert</span>
                  <span style={{ color: colors.success }}>.Ai</span>
                </h2>
                <div className="flex-grow-1 ms-2">
                  {isDarkMode &&
                    <Marquee gradient gradientColor="black" className="bg-black text-white" style={{borderRadius: "4px", height: "35px"}}>
                      {tickerText}
                    </Marquee>
                  }
                </div>
              </div>
            {window.location.pathname !== '/setup' && renderMenu()}
          </div>
          <div className="col-1 d-none d-lg-flex text-center align-items-end cursor-pointer p-2">
            {window.location.pathname !== '/setup' && (
              <div>
                <ThemeToggle onChange={toggleTheme} />
                <br />
                {/* <NavLink to="/admin" onClick={() => { menuTracking('Admin') }}>
                  <Icon name="PersonGear" className="fs-4 text-white" />
                </NavLink> */}
              </div>
            )}
          </div>
        </div>
        <div className="row flex-grow-1 overflow-scroll m-0 height-minus-header bg-primary body-container">
          <div className="col-12 overflow-scroll h-100vh">
            <Routes>
              <Route path="/signin" element={<SignIn settings={settings} />} />
              <Route path="/executive" element={<Globe settings={settings} />} />
              <Route path="/" element={isAuthenticated() ? <Dashboard settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/optimize" element={isAuthenticated() ? <Optimize settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/optimize/:id" element={isAuthenticated() ? <Optimize settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/health" element={isAuthenticated() ? <SystemHealth settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/health/:id" element={isAuthenticated() ? <SystemHealth settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/security" element={isAuthenticated() ? <DataSecurity settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/security/:id" element={isAuthenticated() ? <DataSecurity settings={settings} /> : <Navigate to="/" replace />} />
              <Route path="/admin" element={isAuthenticated() ? <Admin settings={settings} changeMonitor={monitor => setSettings({...settings, monitor})} /> : <Navigate to="/" replace />} />
              <Route path="/admin/:id" element={isAuthenticated() ? <Admin settings={settings} changeMonitor={monitor => setSettings({...settings, monitor})} /> : <Navigate to="/" replace />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes> 
        </div>
        </div>
      </div>
    </Router>
  );
}

export default App;