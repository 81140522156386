import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Container, ListGroup, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { apiRead, apiCreate, apiUpdate, apiDelete } from '../utils/apiUtils'
import Select from 'react-select';
import Icon from './common/Icons'
import { useTheme } from '../styles/Theme'
import { colors } from '../styles/colors'

const purifyConfig = {
  ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'u', 'ol', 'ul', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'div', 'a', 'b'],
  ALLOWED_ATTR: ['class', 'href', 'target', 'rel', 'style']
};

const AdminWidget = ({ item, apiUrl }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const showDetails = id === item.goTo;

  const {theme} = useTheme();
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const fetchData = async () => {
      if (item.apiEndpoint && apiUrl && item.listView) {
        try {
          const result = await apiRead(item.apiEndpoint, apiUrl);
          setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [item.apiEndpoint, apiUrl, item.listView]);

  const setFormDataDefaults = (dataItem) => {
    let itemForm = item.form;
    console.log(itemForm)
  }

  const openDetails = () => {
    item.form && setFormDataDefaults(formData)
    navigate(`/admin/${item.goTo}`);
  };

  const openUpdate = (dataItem) => {
    setFormData(dataItem);
    navigate(`/admin/${item.goTo}`);
  };

  const closeDetails = () => {
    setFormData({})
    navigate("/admin");
  };

  const deleteDataItem = (dataItem) => {
    let newData = data.filter(item => {
      return item.primaryKey !== dataItem.primaryKey;
    })
    setData(newData)
    apiDelete(item.apiEndpoint, dataItem, apiUrl)
  }

  const handleInputChange = (name, value, type = 'text') => {
    setFormData(prev => {
      const newData = { ...prev };
      const nameParts = name.split('.');
      
      if (nameParts.length === 1) {
        // Handle top-level fields
        newData[name] = type === 'checkbox' ? !!value : value;
      } else {
        // Handle nested fields
        let current = newData;
        for (let i = 0; i < nameParts.length - 1; i++) {
          if (!current[nameParts[i]]) {
            current[nameParts[i]] = {};
          }
          current = current[nameParts[i]];
        }
        current[nameParts[nameParts.length - 1]] = type === 'checkbox' ? !!value : value;
      }
  
      return newData;
    });
  
    // Clear error for this field
    setFormErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[name];
      return newErrors;
    });
  };

  const renderFormElement = (element, prefix = '') => {
    if (element.condition && formData[element.condition.field] !== element.condition.value) {
      return null;
    }
  
    const fullName = `${prefix}${element.name}`;
  
    const getNestedValue = (obj, path) => {
      return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : undefined;
      }, obj);
    };

    const selectStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: isDarkMode ? colors.darkBackground : colors.lightBackground,
        borderColor: formSubmitted && element.required !== false && !getNestedValue(formData, fullName) 
          ? 'red' 
          : isDarkMode ? colors.darkBorder : colors.lightBorder,
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: isDarkMode ? colors.darkBackground : colors.lightBackground,
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? isDarkMode ? colors.darkHighlight : colors.lightHighlight
          : isDarkMode ? colors.darkBackground : colors.lightBackground,
      }),
    };
  
    const renderInput = () => {
      switch (element.type) {
        case 'select':
          const selectOptions = element.options[0] && typeof element.options[0] === 'object'
            ? element.options
            : element.options.map(option => ({ value: option, label: option }));
          return (
            <Select
              options={selectOptions}
              name={fullName}
              value={selectOptions.find(option => option.value === getNestedValue(formData, fullName)) || null}
              onChange={(selectedOption) => {
                handleInputChange(fullName, selectedOption ? selectedOption.value : '', 'select');
              }}
              placeholder={`Select ${element.label}`}
              isClearable={element.required === false}
              isSearchable={true}
              className={formErrors[fullName] ? 'is-invalid' : ''}
              styles={selectStyles}
            />
          );
        case 'textarea':
          return (
            <textarea 
              className="form-control" 
              id={fullName} 
              name={fullName} 
              rows="3" 
              onChange={(e) => handleInputChange(fullName, e.target.value, 'textarea')}
              value={getNestedValue(formData, fullName) || ''}
            ></textarea>
          );
        case 'checkbox':
          return (
            <input 
              type="checkbox" 
              className="form-check-input" 
              id={fullName} 
              name={fullName} 
              onChange={(e) => handleInputChange(fullName, e.target.checked, 'checkbox')}
              checked={getNestedValue(formData, fullName) || false}
            />
          );
          case 'radio':
            if (element.options.length <= 3) {
              // Render options in a single row with equal spacing
              return (
                <div className="row">
                  {element.options.map((option, optIndex) => (
                    <div key={optIndex} className="col-4">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id={`${fullName}-${optIndex}`}
                          name={fullName}
                          value={option}
                          checked={getNestedValue(formData, fullName) === option}
                          onChange={(e) => handleInputChange(fullName, e.target.value, 'radio')}
                        />
                        <label className="form-check-label" htmlFor={`${fullName}-${optIndex}`}>
                          {option}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              );
            } else {
              // Render options as a grid
              return (
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  {element.options.map((option, optIndex) => (
                    <div key={optIndex} className="col">
                      <div className="card h-100">
                        <div className="card-body">
                          <h5 className="card-title">{option.split('(')[0].trim()}</h5>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id={`${fullName}-${optIndex}`}
                              name={fullName}
                              value={option}
                              checked={getNestedValue(formData, fullName) === option}
                              onChange={(e) => handleInputChange(fullName, e.target.value, 'radio')}
                            />
                            <label className="form-check-label" htmlFor={`${fullName}-${optIndex}`}>
                              {option.includes('(') ? option.match(/\(([^)]+)\)/)[1] : option}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }
        case 'group':
          return (
            <div className="border p-3 mb-3">
              <h5>{element.label}</h5>
              {element.elements.map((subElement, subIndex) => (
                <React.Fragment key={subIndex}>
                  {renderFormElement(subElement, `${fullName}.`)}
                </React.Fragment>
              ))}
            </div>
          );
        default:
          return (
            <input 
              type={element.type} 
              className="form-control" 
              id={fullName} 
              name={fullName} 
              required={element.required !== false} 
              onChange={(e) => handleInputChange(fullName, e.target.value, element.type)}
              value={getNestedValue(formData, fullName) || ''}
            />
          );
      
    };
  };

  const renderLabel = () => (
    <label htmlFor={element.name} className="form-label">
      {element.label}
      {element.helpText && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${element.name}`}>{element.helpText}</Tooltip>}
        >
          <span className="ms-2">
            <Icon name="InfoCircle" size={16} />
          </span>
        </OverlayTrigger>
      )}
    </label>
  );

  if (element.type === 'group') {
    return renderInput();
  }

  return (
    <div className="mb-3">
      {renderLabel()}
      {renderInput()}
    </div>
  );
};

  const renderWidget = () => {
    const operations = item.operations || {}
    return (
      <div className="row flex-grow-1 m-0 p-0">
      <div className="col gy-2">
          <div 
            className="p-3 card" 
            style={{ height: '97%' }} 
          >
            <h5><Icon name={item.icon} className="mb-2" /> &nbsp; {item.label}</h5>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text, purifyConfig) }} />
            {item.listView && data.length > 0 && (
              <div className="admin-widget-list flex-grow-1 overflow-scroll m-0 p-0">
              <div className="mt-3">
                <ListGroup variant="flush">
                  {data.map((dataItem, dataIndex) => (
                    <ListGroup.Item 
                      key={dataIndex} 
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="text-start">
                        {item.listView.map((key, keyIndex) => (
                          <span key={keyIndex} className="me-2">{dataItem[key]}</span>
                        ))}
                      </div>
                      <ButtonGroup>
                        {operations.read && (
                          <Button variant="outline-primary" size="sm" onClick={openDetails}>
                            {operations.read}
                          </Button>
                        )}
                        {operations.update && (
                          <Button variant="outline-secondary" size="sm" onClick={() => openUpdate(dataItem)}>
                            {operations.update}
                          </Button>
                        )}
                        {operations.delete && (
                          <Button variant="outline-danger" size="sm" onClick={() => deleteDataItem(dataItem)}>
                            {operations.delete}
                          </Button>
                        )}
                      </ButtonGroup>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
              </div>
            )}
            { operations.create && (
              <Container className="text-center mt-3">
                <Button variant="dark" onClick={openDetails}>{operations.create}</Button>
              </Container>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((prev, curr) => {
      return prev && prev[curr] !== undefined ? prev[curr] : undefined;
    }, obj);
  };
  
  const validateFormData = (elements, formData, errors, prefix = '') => {
    elements.forEach(element => {
      if (element.type === 'group') {
        validateFormData(element.elements, formData, errors, `${prefix}${element.name}.`);
      } else if (element.required !== false) {
        const fullName = `${prefix}${element.name}`;
        const value = getNestedValue(formData, fullName);
        if (value === undefined || value === '' || value === null) {
          errors[fullName] = 'This field is required';
        }
      }
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
  
    let errors = {};
    validateFormData(item.form.elements, formData, errors);
    
    // If there are errors, set them and stop submission
    if (Object.keys(errors).length > 0) {
      console.log(errors)
      setFormErrors(errors);
      return;
    }
  
    let result;
    let newData = JSON.parse(JSON.stringify(data))
    if(!newData) newData = []
    
    try {
      if (formData.primaryKey) {
        // If formData has a primaryKey, it's an update operation
        for(let i=0; i<newData.length; i++){
          if(newData[i].primaryKey === formData.primaryKey) newData[i] = formData
        }
        setData(newData)
        result = await apiUpdate(item.apiEndpoint, formData, apiUrl);
      } else {
        // If there's no primaryKey, it's a create operation
        result = await apiCreate(item.apiEndpoint, formData, apiUrl);
        formData.primaryKey = result.primaryKey
        if(item.listView) newData.push(formData)
        setData(newData)
      }
      
      // Clear any existing form errors
      setFormErrors({});
      setFormSubmitted(false);
      
      // Close the form
      closeDetails();
    } catch (error) {
      // Handle API errors
      console.error("API error:", error);
      setFormErrors({ api: "An error occurred while submitting the form. Please try again." });
    }
  };

  const renderDetailView = () => (
    <Modal 
      show={showDetails} 
      onHide={closeDetails} 
      size="lg" 
      aria-labelledby="sh-contained-modal-title-vcenter" 
      centered 
    >
      <Modal.Header closeButton>
        <Modal.Title id="sh-contained-modal-title-vcenter">
          {item.form?.title || item.label || "Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3">
        {item.videos ? (
        <div className="video-list">
          {item.videos.map((video, index) => (
            <div key={index} className="video-item mb-4">
              <h5>{video.title}</h5>
              <p>{video.description}</p>
              <video controls width="100%">
                <source src={video.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </div>
      ) : item.form ? (
          <>
            {item.form.text && (
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.form.text, purifyConfig) }} />
            )}
            <form onSubmit={onFormSubmit}>
              {item.form.elements.map((element, index) => (
                <React.Fragment key={index}>
                  {renderFormElement(element)}
                </React.Fragment>
              ))}
              <Container className="mt-3 d-flex justify-content-end">
                <Button type="submit" variant="primary">Submit</Button>
              </Container>
            </form>
          </>
        ) : (
          <div>
            <h5>Loaded Data:</h5>
            <ul>
              {data.map((item, index) => (
                <li key={index}>{JSON.stringify(item)}</li>
              ))}
            </ul>
          </div>
        )}
        </div>
      </Modal.Body>
    </Modal>
  );

  const renderLoading = () => (
    <div className="col gy-2">
      <div className="h-100">
        <div className="p-3 item" style={{ height: '97%' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return renderLoading();
  }

  return (
    <>
      {renderWidget()}
      {renderDetailView()}
    </>
  );
};

export default AdminWidget;