// import {colors, chartColors} from '../../styles/colors';
const color = "#00DDFF"

const AnimationWrapper = ({ Animation }) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100">
      <div className="text-center d-flex flex-column align-items-center">
          <Animation />
      </div>
    </div>
  )
}


export const Heartbeat = () => {
  const HeartbeatAnimation = () => (
    <div 
      className="w-100 mx-auto position-relative rounded p-2 heartbeat-container" 
      style={{ "--color": color }}
    >
      <svg
        viewBox="0 0 200 40"
        className="heartbeat-svg"
      >
        <path
          d="M0,20 L25,20 L27.5,-10 L30,50 L32.5,20 L35,20 L37.5,5 L40,35 L42.5,20 L70,20 M70,20 L95,20 L97.5,-10 L100,50 L102.5,20 L105,20 L107.5,5 L110,35 L112.5,20 L140,20 M140,20 L165,20 L167.5,-10 L170,50 L172.5,20 L175,20 L177.5,5 L180,35 L182.5,20 L210,20 M210,20 L235,20 L237.5,-10 L240,50 L242.5,20 L245,20 L247.5,5 L250,35 L252.5,20 L280,20"
          className="heartbeat-path"
        />
      </svg>
    </div>
  );

  return <AnimationWrapper Animation={HeartbeatAnimation} />;
};


export const Radar = ({ level, fileName }) => {
  // const widgetId = fileName && typeof fileName === "string" ? fileName.replace(".JSON", "") : null;

  const getThreatsClass = (score) => {
    if (score === 0) return "no-threats";
    if (score === 1) return "few-threats";
    return "many-threats";
  };

  const threatsClass = getThreatsClass(level);

  const RadarAnimation = () => (
    <div className="radar-container" style={{ "--color": color }}>
      <div className={`radar-base ${threatsClass}`}>
        <div className="radar-beam"></div>
        <div className="threats">
          <div className="threat t1"></div>
          <div className="threat t2"></div>
          <div className="threat t3"></div>
          <div className="threat t4"></div>
          <div className="threat t5"></div>
        </div>
      </div>
    </div>
  );

  return <AnimationWrapper Animation={RadarAnimation} />;
};


export const Cube = ({ level, size = 'medium' }) => {
  const getOptimizationClass = (score) => {
    if (score === 0) return "optimized";
    if (score === 1) return "improving";
    return "needs-work";
  };

  const optimizationClass = getOptimizationClass(level);
  const cubeClass = `cube-${size}`;

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "3px",
      marginLeft: "0px"}}>
      <div className={`${cubeClass} ${optimizationClass}`}>
        <div className="face one"></div>
        <div className="face two"></div>
        <div className="face three"></div>
        <div className="face four"></div>
        <div className="face five"></div>
        <div className="face six"></div>
      </div>
    </div>
  );
};

// JavaScript function
export const Ticker = ({ text, size = 'md', color = '#fff', bgColor}) => {
  const widthMap = {
    sm: 200,
    md: 500,
    lg: 900
  };

  const approximateWidth = widthMap[size] || widthMap.md;
  const optimalCharCount = Math.floor(approximateWidth / 10); // Assuming average char width of 10px

  const repeatedText = getRepeatedText(text, approximateWidth);

  const tickerId = `ticker-${Math.random().toString(36).substr(2, 9)}`;

  const html = 
    <div id={tickerId} className="ticker" style={{background: bgColor}}>
      <div className="ticker-content" style={{color: color}}>
        <span className="ticker-text">{repeatedText}</span>
      </div>
    </div>
  ;

  return html;
};

function getRepeatedText(text, size) {
  const approximateTextWidth = text.length * 10; // Rough estimate of text width
  const repetitions = Math.ceil(size / approximateTextWidth);
  
  if (repetitions <= 1) return text;
  
  return `${text} `.repeat(repetitions).trim();
}