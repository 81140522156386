import React from 'react';
import { Modal, Accordion } from 'react-bootstrap';
import { highlight } from 'sql-highlight';
import { format } from 'sql-formatter';
import parse from 'html-react-parser';
import {Icon, Gauge, Risk, SolutionRisk} from './common/index'
import Activity from './Activity'
import { useTheme } from '../styles/Theme';

export function RootCauseAnalysis(props){
  function diamond(text, key){
    return(
      <div key={key} className="dp-container">
        <div className="diamond align-items-center d-flex"><div className="diamond-content">{text}</div></div>
      </div>
    )
  }
  
  function arrow(key){
    return (
      <div key={key} className="arrow-container align-items-center d-flex"><Icon name="ArrowRight" className="fs-1" style={{color: '#dc3545'}} /></div>
    )
  }
  
  function pill(text, key){
    return (
      <div key={key} className="dp-container"><div className="pill align-items-center d-flex"><div className="pill-content">{text}</div></div></div>
    )
  }
  let decisionPath = props.rootCauseAnalysis.split('->');
  let toRender = [];
  let key = "decision-path-key-";
  for (let i=1; i < decisionPath.length; i++){
    key = key + i;
    let item = decisionPath[i-1];
    toRender.push(diamond(item, key))
    toRender.push(arrow(key + "-arrow"))
  }
  key = key + "last";
  toRender.push(pill(decisionPath.slice(-1), key));
  return (<div className="d-inline-block">{toRender}</div>)
}

function keyPress(e) {
  if (!e.ctrlKey && !e.metaKey) return;
  let { key, target } = e;
  let selection = '';
  if (key === 'a') {
    window.getSelection().selectAllChildren(target);
  } else if (key === 'c') {
    selection = window.getSelection().toString();
    navigator.clipboard.writeText(selection);
  }
}

function selectStatus(item, updateTracker) {
  return (
    <div className="input-group mb-3">
      <select
        className="form-select"
        id={`tracker-detail-select`}
        onChange={e => updateTracker(item, e.target.value)}
        value={item.Status}
      >
        <option value="Develop">Develop</option>
        <option value="Test">Test</option>
        <option value="Deploy">Deploy</option>
        <option value="Observe">Observe</option>
        <option value="Closed">Closed</option>
      </select>
    </div>
  );
}

function DetailView({ item, updateTracker, show, onHide }) {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  let { Category, Root_Cause_Analysis, Root_Cause_Code, Solution_Code, Impact_Level, Risk_Level, Server, servername } = item;
  let Root_Cause = '';
  let Issue = '';
  
  let prio_risk_level = 0;
  if(Impact_Level > 0.2) prio_risk_level = 1;
  if(Impact_Level > 0.6) prio_risk_level = 2;

  function getSubstringBeforeBracket(str) {
    const bracketIndex = str.indexOf('[');
    if (bracketIndex === -1) {
      // If '[' is not found, return the entire string
      return str;
    }
    return str.substring(0, bracketIndex);
  }

  const categoryDefinitions = {
    "Access Control": {
      icon: "LockFill"
    },
    "Query Monitoring": {
      icon: "EyeFill"
    },
    "Data Protection": {
      icon: "ShieldLock"
    },
    "Database Operations": {
      icon: "Diagram3"
    },
    "Security Patches and Updates": {
      icon: "Bandaid"
    },
    "Database Alerts": {
      icon: "BellFill"
    },
    "Root Cause": {
      icon: "ExclamationTriangle"
    },
    "Solution": {
      icon: "CheckCircle"
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>
            {getSubstringBeforeBracket(Issue)} 
          </h3>
          <p>Server: {Server || servername} </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-4">
              <Risk level={prio_risk_level} />
              <p>Category: {Category}</p>
            </div>
            <div className="col-4">
              <Gauge percent={Impact_Level} />
              <h3> &nbsp; &nbsp;Impact</h3>
            </div>
            <div className="col-4">
              <h3>Status</h3>
              {selectStatus(item, updateTracker, item.Id)}
            </div>
          </div>
        </div>
        <hr className="hr" />
        <h5>Root Cause Analysis</h5>
        <div className="p-2 solution overflow-hidden">
          <Activity animate={isDarkMode} activities={Root_Cause_Analysis} categoryDefinitions={categoryDefinitions} />
        </div>
        <h5>Root Cause: {Root_Cause}</h5>
        <br />
        {Root_Cause_Code && (
          <Accordion>
            <Accordion.Item eventKey="0" style={{border: "0px"}}>
              <Accordion.Header><h5>Code related to this Issue</h5></Accordion.Header>
              <Accordion.Body style={{padding: "0px", margin: "0px"}}>
                <div className="solution p-2 sql-highlight-wrapper">
                  <pre>{parse(highlight(format(Root_Cause_Code, {language: 'tsql', indent: '  '}), { html: true }))}</pre>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {Solution_Code && (
          <>
            <br />
            <SolutionRisk level={Risk_Level} />
            <h5>Solution</h5>
            <div className="solution p-2 sql-highlight-wrapper">
              <pre tabIndex={-1} onKeyDown={e => keyPress(e)}>
                {parse(highlight(format(Solution_Code, {language: 'tsql', indent: '  '}), { html: true }))}
              </pre>
              <Icon name="Files"
                className="position-absolute bottom-0 end-0 fs-4 cursor-copy me-4 mb-4"
                onClick={() => navigator.clipboard.writeText(Solution_Code)}
              />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DetailView;
