import React, { useState, useEffect } from 'react';
import { fetchData } from '../utils/apiUtils';
import AdminWidget from './AdminWidget';
import { Loading } from './common/index';

const Admin = ({ settings }) => {
  const [layoutData, setLayoutData] = useState([]);

  useEffect(() => {
    const fetchAll = async () => {
      if (settings.apiUrl && settings.apiUrl !== null) {
        const newLayoutData = await fetchData('/Admin.JSON');
        if (JSON.stringify(layoutData) !== JSON.stringify(newLayoutData.data)) {
          if(!newLayoutData || !newLayoutData.data) return
          setLayoutData(newLayoutData.data);
        }
      }
    };

    fetchAll();
  }, [settings.apiUrl, layoutData]);

  return (
    <div className="widget-container row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 overflow-scroll p-2">
      { layoutData.length > 0 ? (
        layoutData.map((item, index) => (
          <AdminWidget key={`dashboard-${index}`} item={item} apiUrl={settings.apiUrl} />
        ))
      ) : (<div className="container-fluid">
        <Loading />
        </div>
      )}
    </div>
  );
}

export default Admin;
