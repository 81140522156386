import React, { useState, useEffect } from 'react';
import { getTrackerStats } from '../utils/apiUtils';
import { useNavigate, Link } from 'react-router-dom';
import { fetchData } from '../utils/apiUtils';
import { MainScore, Indicator, Loading, Gauge } from './common/index';
import { Container, Row, Col } from 'react-bootstrap'
import Activity from './Activity'
import { Radial, MultiRadial } from './common/index'
import {Icon} from './common/index'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTheme } from '../styles/Theme'; 

function Dashboard({ settings }) {
  const [dashboard, setDashboard] = useState([]);
  const [trackerData, setTrackerData] = useState(null);
  const [isVisible] = useState(true); // for tracking // setIsVisible

  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    let refreshInterval;

    const startRefreshInterval = () => {
    refreshInterval = setInterval(fetchAll, 60000);
    };

    const stopRefreshInterval = () => {
    clearInterval(refreshInterval);
    };

    const fetchAll = async () => {
    if (settings.apiUrl !== null) {
      const trackerStats = await getTrackerStats(settings.apiUrl);
      if (JSON.stringify(trackerData) !== JSON.stringify(trackerStats)) {
        setTrackerData(trackerStats);
      }
      const dashboardData = await fetchData('/Dashboard.JSON')
      if(dashboard.length > 0 && trackerData){
          let optimizationIndicators = dashboardData.data[2].indicators
          let totalIssueCount = trackerData.openIssues + trackerData.solutionsInProgress + trackerData.completedSolutions
          optimizationIndicators.forEach(indicator => {
            if(indicator.label === "Completed"){ 
              let percentage = Math.floor((trackerData.completedSolutions / (totalIssueCount)) * 100)
              let level = 0
              if(percentage < 90) level = 1
              if(percentage < 70) level = 2
              indicator.label = "Completed: " + percentage + "%"
              indicator.risk = level
            } else if(indicator.label === "Open Tasks"){
              let open = totalIssueCount - trackerData.completedSolutions
              let level = 0 
              if(open > 10) level = 1
              if(open > 20) level = 2
              indicator.label = "Open Tasks: " + open
              indicator.risk = level
            } else if(indicator.label === "Avg Time"){
              let avgTime = trackerData.averageTimeForClosedIssues
              let level = 0
              if(avgTime > 5) level = 1
              if(avgTime > 15) level = 2
              indicator.risk = level
              indicator.label = "Avg Time: " + avgTime + " days"
            }
          })
      }
      if (JSON.stringify(dashboardData.data) !== JSON.stringify(dashboard)){
          if(!dashboardData || !dashboardData.data) return
          setDashboard(dashboardData.data)
      }
    }
    };
   fetchAll();

   if (isVisible) {
     startRefreshInterval();
   }

   return () => {
     stopRefreshInterval();
   };
  }, [settings.apiUrl, dashboard, isVisible, trackerData]);
 
  function DWidget({ item }) {
    const { goTo, indicators } = item; // title

    const renderIndicator = (indicator, index) => {
      const goToFilter = `${goTo}/`
      return <Indicator indicator={indicator} goTo={goToFilter} index={index} />
    };

    return (
      <div className="col gy-3">
        <div className="card p-0 mt-0 pt-4 pb-2">
          <Container>
            <Row className="mb-2">
              <Col xs={8}>
                <MainScore item={item} goTo={item.goTo} />
              </Col>
              <Col xs={4}>
                {renderIndicator(indicators[0])}
                {renderIndicator(indicators[1])}
              </Col>
            </Row>
            <Row>
              {indicators.slice(2, 5).map((indicator, index) => (
                <Col xs={4} key={`indicator-${index + 2}`}>
                  {renderIndicator(indicator)}
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  let toRender = []
  
  const categoryDefinitions = {
    "Access Control": {
      icon: "LockFill"
    },
    "Query Monitoring": {
      icon: "EyeFill"
    },
    "Data Protection": {
      icon: "ShieldLock"
    },
    "Database Operations": {
      icon: "Diagram3"
    },
    "Security Patches and Updates": {
      icon: "Bandaid"
    },
    "Database Alerts": {
      icon: "BellFill"
    },
    "Root Cause": {
      icon: "ExclamationTriangle"
    },
    "Solution": {
      icon: "CheckCircle"
    }, 
    "Clustered Index Scan": {
      icon: "CheckCircle"
    }
  }

  const IconWithTooltip = ({ name, tooltip, className }) => (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${name}`}>{tooltip}</Tooltip>}
    >
      <span>  {/* Wrapper span because OverlayTrigger needs a DOM element as its child */}
        <Icon name={name} className={className} />
      </span>
    </OverlayTrigger>
  );

  const TopIssue = ({item, icon, isHighest, id}) => {
    const categoryIcon = categoryDefinitions[item.Category].icon;
    return <>
      <IconWithTooltip 
        name={icon} 
        tooltip={isHighest ? "This will have the highest positive impact on your system." : "This will have the 2nd highest positive impact on your system."} 
        className="me-1" 
      />
      <b>{isHighest ? "Highest Impact Opportunity" : "Next Impact Opportunity"}</b>
      <div className="d-flex align-items-center mb-1">
        <IconWithTooltip 
          name={categoryIcon} 
          tooltip={"Category: " + item.Category} 
          className="me-1" 
        />
        <span className="small fw-bold">
          <Link 
            to={`/optimize?Category=${encodeURIComponent(item.Category)}`}
            className="text-decoration-underline"
          >
            {item.Category}
            </Link>
            </span>
      </div>
      <div className="mb-2">
        <Link 
          to={`/optimize/${id}`}
          className="btn btn-primary d-inline-flex align-items-center"
        >
          <Icon name="Lightning" className="me-2" /> {/* Bootstrap icon for optimization */}
          <span>{item.Name}</span>
        </Link>
      </div>
      <div className="small text-muted overflow-auto">
        <IconWithTooltip 
          name="Server" 
          tooltip="The Server Instance where the opportunity was found." 
          className="me-1" 
        />
        <Link 
          to={`/optimize?Server=${encodeURIComponent(item.Server || item.servername)}`}
          className="text-decoration-underline"
        >
          {item.Server || item.servername}
        </Link>
      </div>
    </>
  }


  if(dashboard.length > 0){
    toRender = [
      <div className="row flex-grow-1 m-0 p-0 w-100" key="activity-row">
        <div className="col gy-2">
          <div className="card p-3 overflow-hidden">
                <div className="dashboard-top-issue bg-body p-3" style={{top: "0px"}}>
                  <TopIssue item={dashboard[0].recommendations[0].Root_Cause_Analysis[0]} id={dashboard[0].recommendations[0].Id} icon="Bullseye" isHighest={true} />
                </div>
                <Activity animate={isDarkMode} activities={dashboard[0].recommendations[0].Root_Cause_Analysis} categoryDefinitions={categoryDefinitions}/>
                <hr style={{margin: "9px"}} />
                <div className="dashboard-top-issue bg-body p-3" style={{bottom: "0px"}}>
                  <TopIssue item={dashboard[0].recommendations[1].Root_Cause_Analysis[0]} id={dashboard[0].recommendations[0].Id} icon="ArrowUpCircleFill" />
                </div>
                <Activity animate={isDarkMode} activities={dashboard[0].recommendations[1].Root_Cause_Analysis} categoryDefinitions={categoryDefinitions} />
          </div>
        </div>
      </div>,
      <DWidget item={dashboard[1]} key="dashboard-widget-2" />,
      <DWidget item={dashboard[2]} key="dashboard-widget-3" />,
      <DWidget item={dashboard[3]} key="dashboard-widget-4" />
    ]
  }
    
  if (toRender.length > 0) {
    return (
      <div className="widget-container row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 overflow-scroll p-2">
          {toRender}
      </div>
    );
  } else {
    return ( <div className="container-fluid h-100">
      <Loading />
    </div> );
  }
}

export default Dashboard;


{/* <Radial percent={0.87} label='Custom Label' />
<MultiRadial
  series={[0.67, 0.84, 0.97, 0.61, 0.71]} 
  labels={['Checks', 'Time Saved', 'Completed', 'Prevented', 'Open Tasks']}
  text={32}
/> */}