import unconfiguredAxios from 'axios';

const axios = unconfiguredAxios.create({
  withCredentials: true
});

// ====== fetching static JSONS =======
export async function fetchData(url) {
  try {
    const response = await axios.get(url);
    const data = response.data;
    // Check the size of the response data
    const contentLength = response.headers['content-length'];
    if (contentLength && parseInt(contentLength, 10) > 5000000) {
      console.log('Response too large, max size is 5MB');
      return { error: 'Response too large' };
    }
    // Check if the response was served from cache
    const fromCache = response.headers.age !== undefined;
    const maxAge = response.headers['cache-control']
      ? parseInt(response.headers['cache-control'].split('max-age=')[1], 10)
      : undefined;
    const age = response.headers.age ? parseInt(response.headers.age, 10) : undefined;

    if ((fromCache && age <= maxAge) || response.status === 304) {
      // Response was served from cache (disk or memory) and is still fresh
      console.log('Using cached response');
      return { data, fromCache: true };
    }
    return { data, fromCache: false };
  } catch (error) {
    console.error('Error fetching data:', error);
    if (error.response) {
      console.error('Error data:', error.response.data);
      return { error: 'Error fetching data' };
    }
    return { error: 'Unknown error occurred' };
  }
}

export async function loadSettings() {
  try {
    const response = await fetchData('/Settings.JSON');
    return response;
  } catch (error) {
    console.log('Error loading settings:', error);
    throw error;
  }
}

export async function loadTracker() {
  try {
    const response = await fetchData('/Tracker.JSON');
    return response;
  } catch (error) {
    console.log('Error loading tracker:', error);
    throw error;
  }
}

export async function loadDashboard() {
  // try {
  //   const response = await fetchData('/DashboardData/Dashboard_List.JSON');
  //   const dashboardList = response.data
  //   const dashboardData = [];
  //   for (const fileName of dashboardList) {
  //     try {
  //       const res = await fetchData(`/DashboardData/${fileName}`);
  //       const response = res.data
  //       let data;
  //       if (typeof response === 'string') {
  //         try {
  //           // Convert the string to a valid JSON string
  //           const validJsonString = response.replace(/[\u0000-\u001F]/g, ''); // Remove control characters
  //           data = JSON.parse(validJsonString);
  //         } catch (e) {
  //           console.error(`Error parsing JSON for ${fileName}:`, e);
  //           data = null; // or handle the error in a different way
  //         }
  //       } else {
  //         data = response;
  //       }
  //       dashboardData.push({ fileName, data });
  //     } catch (e) {
  //       console.log("error loading" + fileName);
  //     }
  //   }
  //   return {data:dashboardData};
  // } catch (error) {
  //   console.error('Error loading dashboard:', error);
  //   return [];
  // }
}

// ======== backend API calls ========

// Generic GET request
export const apiRead = async (endpoint, apiUrl) => {
  try {
    const response = await axios.get(`${apiUrl}/${endpoint}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    return { error: `Failed to fetch data from ${endpoint}` };
  }
};

// Generic POST request
export const apiCreate = async (endpoint, data, apiUrl) => {
  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error posting data to ${endpoint}:`, error);
    return { error: `Failed to post data to ${endpoint}` };
  }
};

// Generic PUT request
export const apiUpdate = async (endpoint, data, apiUrl) => {
  try {
    const response = await axios.put(`${apiUrl}/${endpoint}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating data at ${endpoint}:`, error);
    return { error: `Failed to update data at ${endpoint}` };
  }
};

// Generic DELETE request
export const apiDelete = async (endpoint, item, apiUrl) => {
  try {
    const response = await axios.delete(`${apiUrl}/${endpoint}/${item.primaryKey}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting data from ${endpoint}:`, error);
    return { error: `Failed to delete data from ${endpoint}` };
  }
};

// export async function loadTracker(apiUrl) {
//   try {
//     const res = await axios.get(`${apiUrl}/tracker`);
//     return res
//   } catch (error) {
//     console.log('Error loading Issue Tracker');
//     return [];
//   }
// }

// Settings Routes
export const addMonitorServer = async (server, apiUrl) => {
  try {
    const response = await axios.post(`${apiUrl}/settings/monitor`, server);
    return response.data;
  } catch (error) {
    console.error('Error adding server to monitor:', error);
    return { error: 'Failed to add server to the monitor list' };
  }
};

export const removeMonitorServer = async (serverUrl, apiUrl) => {
  try {
    const response = await axios.delete(`${apiUrl}/settings/monitor/${serverUrl}`);
    return response.data;
  } catch (error) {
    console.error('Error revmoving server from monitor:', error);
    return { error: 'Failed to remove server from the monitor list' };
  }
};

// tracker routes
export const updateIssueStatus = async (issueId, status, apiUrl) => {
  try {
    const response = await axios.patch(`${apiUrl}/tracker/${issueId}`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating issue status:', error);
    return { error: 'Failed to update issue status' };
  }
};

export const assignIssueToUser = async (issueId, userId, apiUrl) => {
  try {
    const response = await axios.put(`${apiUrl}/tracker/${issueId}/assign`, { userId });
    return response.data;
  } catch (error) {
    console.error('Error assigning issue to user:', error);
    return { error: 'Failed to update issue userId' };
  }
};

export const getTrackerStats = async (apiUrl) => {
  try {
    const response = await axios.get(`${apiUrl}/tracker/stats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tracker stats:', error);
    return { error: 'Failed to load tracker stats' };
  }
};

// User Routes
export const signUp = async (username, password, apiUrl) => {
  try {
    const response = await axios.post(`${apiUrl}/auth/signup`, { username, password });
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    return { error: 'Failed to sign up.' };
  }
};

export const loginUser = async (username, password, apiUrl) => {
  try {
    const response = await axios.post(`${apiUrl}/auth/login`, { username, password });
    if (response.status === 200) {
      const { token, redirectUrl } = response.data;
      localStorage.setItem('token', token);
      return { redirectUrl };
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    console.error('Error logging in:', error);
    return { error: 'Failed to log in.' };
  }
};

export const logoutUser = async (apiUrl) => {
  try {
    const response = await axios.post(`${apiUrl}/auth/logout`);
    if (response.status === 200) {
      localStorage.removeItem('token');
      window.location.href = '/';
      return response.data;
    } else {
      throw new Error('Logout failed');
    }
  } catch (error) {
    console.error('Error logging out:', error);
    return { error: 'Failed to log out.' };
  }
};

export const fetchUsers = async (apiUrl) => {
  try {
    const response = await axios.get(`${apiUrl}/auth/users`);
    return response.data.users;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};

export const sendTrackingObjectToServer = async (trackingObject, apiUrl) => {
  try {
    // console.log('sending this: ' + JSON.stringify(trackingObject))
    const response = await axios.post(`${apiUrl}/usage`, trackingObject);
    if(response.status === 200){
      // console.log('success')
    }
    return response.data;
  } catch (error) {
    console.error('Error sending usage to server: ', error);
    return { error: 'Usage data could not be sent to the server.' };
  }
};
